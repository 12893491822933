import React, { useEffect, useReducer, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Paper, Stack } from "@mui/material";
import {
  getPipeLineStagesBasedOnPostId,
  updateFromPipeline,
} from "helpers/services/kanban/getKanbanDetails";
import DropTargets from "components/kanban/dropTagets";
import SocketConnection from "hooks/sockets";
import ErrorHandlingToaster from "helpers/Toaster/error";
import KanbanHeader from "components/kanban/header";
import GlobalLoader from "components/loader";
import KanbanGrid from "./kanbanGrid";
import { getSingleJobDetails } from "helpers/services/jobs/jobs";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { roleRestrictions } from "common/constants/roleRestrictions";
import { ACM, BVC, BVS, CA, CS, MODERATOR, SP, TE, ZA } from "common/constants/userModules";
import SearchParamHook from "hooks/searchParams";
export default function Kanban() {
  const { id } = useParams();
  const { sendMessage, lastMessage, connectionStatus } = SocketConnection();
  const [pipeline, setPipeline] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const [showGrid, setShowGrid] = useState("grid");
  const user = useSelector(state => state?.Login);

  const loginState = JSON.parse(localStorage.getItem("authUser"))
  /* Testing of kanban stages restrictions start here */
  let role = loginState?.role;

  const searchParams = SearchParamHook()
  const userId = searchParams.get("user_id")

  let extract;
  if (Object.keys(roleRestrictions).includes(role)) {
    extract = roleRestrictions[role];
  }

  /* Testing of kanban stages restrictions End here */

  useEffect(() => {
    initialFetch(id);
    findJobDetails(id);
  }, [id, updated]);
  useEffect(() => {}, [connectionStatus]);
  useEffect(() => {
    if (
      lastMessage?.data !== undefined ||
      lastMessage?.data !== null ||
      lastMessage?.data !== ""
    ) {
      const resultant = lastMessage?.data;
      if (resultant !== undefined) {
        const data = JSON.parse(resultant);
        if (data?.type === "job_apply_success" || data?.type !== "job_apply_success") {
          initialFetch(id);
          findJobDetails(id)
        }
      }
    }
  }, [lastMessage]);
  // useEffect(() => {}, [pipeline]);
  async function initialFetch(id) {
    const { data, status } = await getPipeLineStagesBasedOnPostId(id);
    // console.log(data?.pipeline_stage_details, "stages");
    /*Testing start here */
    // const modifyPipeline = data?.pipeline_stage_details?.map(each => {
    //   if (extract?.includes(each?.stage_order)) {
    //     return {
    //       ...each,
    //       isDisabled: false,
    //     };
    //   } else {
    //     return {
    //       ...each,
    //       isDisabled: true,
    //     };
    //   }
    // });

    // console.log(modifyPipeline,"modifyPipeline")


    /*Sorting based on score of user start here */

    // let avoidStages = ['Sourced','Applied']
    // const sortedPipeline = modifyPipeline?.map((each)=>{
    //   if(!avoidStages.includes(each.stage_name)){

    //     const sortedScoreCards = each?.user_job_posts_list.sort((a, b) => {
    //       const scoreA = a.score; // Assuming 'score' is the key in your object
    //       const scoreB = b.score;
      
    //       // Sort in descending order (highest score first)
    //       return scoreB - scoreA;
    //   });

    //   return {
    //     ...each,
    //     user_job_posts_list:sortedScoreCards
    //   }

    //   }
    // })

    /*Sorting based on score of user end here */


    setPipeline(data?.pipeline_stage_details);
    // setPipeline(data?.pipeline_stage_details);
    /* Testing end here */

    // setPipeline(data?.pipeline_stage_details); uncomment this after your testing
    if (status === 200) {
      setLoading(false);
    }
  }
  // DropTargets;
  async function findJobDetails(id) {
    const { status, data } = await getSingleJobDetails(id);
    ErrorHandlingToaster({ data, status });
    setJobDetails(data);
  }
  async function handleDrop(x, y, datas) {
    let copy = structuredClone(pipeline);
    const order = datas?.id;
    const cardId = datas?.card_id;
    if (order > x) {
    } else if (order === x) {
    } else {
      // let copy = [...pipeline];
      const cards = copy.find(item => item.stage_order === order);
      const cardsData = cards.user_job_posts_list;
      const cardDetails = cardsData.find(item => item.id === cardId);
      // console.log(cards,copy, cardsData,cardDetails,"v");
      const data = copy.find(item => item.stage_order === x);
      const updatedContext = pipeline.findIndex(item => item.stage_order === x);
      const currentPipelineIndex = pipeline.findIndex(
        item => item.stage_order === order
        );
        data.user_job_posts_list = [cardDetails, ...data.user_job_posts_list];
        // console.log(data, "v");
      const currentCardId = cardsData.findIndex(item => item.id === cardId);
      cardsData.splice(currentCardId, 1);
      copy[currentPipelineIndex] = {
        ...copy[currentPipelineIndex],
        user_job_posts_list: cardsData,
      };
      // console.log(currentPipelineIndex,copy,cardsData, "v");
      // setPipeline([]);
      if (order === 11) {
        sendMessage(
          JSON.stringify({
            type: "myearning",
            data: {
              user_job_post_id: cardId,
            },
            reciever_connectionId: "",
          })
        );
      }
      const result = await updateFromPipeline(
        cardId,
        data?.stage_id,
        cards?.stage_id
      );
      const { status } = result;
      // setPipeline(copy);
      if ([200, 201].includes(status)) {
        toast.success(`sucessfully moved to ${data?.stage_name}`);
        setUpdated(pre=>!pre)
        sendMessage(
          JSON.stringify({
            type: "kanban",
            data: {
              job_post_id: id,
              user_id: cardDetails?.user_id,
            },
            reciever_connectionId: "",
          })
        );
      }
      ErrorHandlingToaster(result);

    }
  }
  function checkDropLocation(x, y, data) {
    const order = data?.id;
    const cardId = data?.card_id;
    // const checkAppliedType = data?.owner_type !== "REFERAL_PARTNER"
    // const evaluation = data?.evaluation
  //  console.log({x,order},"v");
    if (order > x) {
      return false;
    }
    if (role === ZA) {
      if (order === 0) {
        if ([2, 8, 17].includes(x) || order=== x) {
           return true
        }
        return false
      }if (order === 7) {
        if ([8, 17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
       if (order === 8) {
        if ([17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
       if (order === 16) {
        if (x === 16) {
          return true;
        }
        return false;
      }
    }
    if ([CS, CA].includes(role)) {
      if (order === 0) {
        if ([2,8,9,10,12,14,15,16,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 7) {
        if ([8,9,10,12,14,15,16,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 8) {
        if ([9,10,12,14,15,16,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 9) {
        if ([10,12,14,15,16,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 11) {
        if ([12,14,15,16,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 12) {
        if ([13,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if ([13,14].includes(order)) {
        if ([15,16,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order===15) {
        if ([16,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 16) {
        if (x === 16) {
          return true;
        }
        return false;
      }
    }
    if (role === SP) {
      if (order === 0) {
        if ([2,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 1) {
        if ([2,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 16) {
        if (x === 16) {
          return true;
        }
        return false;
      }
    }
    if (role === MODERATOR) {
      if (order === 3) {
        if ([4,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 16) {
        if (x === 16) {
          return true;
        }
        return false;
      }
    }
    if (role === TE) {
      // console.log("im",TE);
      if (order === 2) {
        if ([3,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 10) {
        if ([11,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 16) {
        if (x === 16) {
          return true;
        }
        return false;
      }
    }
    if (role === ACM) {
      if (order === 0) {
        if ([2,5,7,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 4) {
        if ([5,7,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 5) {
        if ([7,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 12) {
        if ([14,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 16) {
        if (x === 16) {
          return true;
        }
        return false;
      }
    }
    if ([BVC, BVS].includes(role)) {
      if ([13,14].includes(order)) {
        if ([15,17].includes(x) || order=== x) {
          return true;
        }
        return false;
      }
      if (order === 16) {
        if (x === 16) {
          return true;
        }
        return false;
      }
    }
    // else if (order < x) {
    //   return true;
    // }
    // else if (order === 0) {
    //   if (x > 1 || x === 0) {
    //     return true;
    //   }
    //   return false;
    // }
    
  else {
      return false;
    }
  }
  function handleGrid(_, val) {
    // console.log(typeof val,val,"v");
    if (val !== null) setShowGrid(val);
  }

 
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <div
          style={{
            overflow: "hidden",
            paddingTop: "0px",
            paddingLeft: "20px",
          }}
        >
          <KanbanHeader
            showGrid={showGrid}
            handleGrid={handleGrid}
            jobDetails={jobDetails}
            setUpdated={setUpdated}
              updated={updated}
            fetchKanban={initialFetch}
          />
          {showGrid === "grid" ? (
            <DndProvider backend={HTML5Backend}>
              <Stack
                direction={"row"}
                spacing={2}
                sx={{
                  overflowX: "scroll",
                }}
              >
                {pipeline?.map((item, index) => (
                  <DropTargets
                    newItem={item}
                    index={index}
                    checkDropLocation={checkDropLocation}
                    handleDrop={handleDrop}
                    cardsList={item.user_job_posts_list}
                    jobDetails={jobDetails}
                    setUpdated={setUpdated}
                    updated={updated}
                  fetchKanban={initialFetch}
                  role={role}
                  />
                ))}
              </Stack>
            </DndProvider>
          ) : (
            <KanbanGrid pipeLine={pipeline} />
          )}
        </div>
      )}
    </>
  );
}
