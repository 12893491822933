import axios from "axios";
import { toast } from "react-hot-toast";
// const API_URL = "http://192.168.29.163:8082/api";
// const API_URL = "http://192.168.0.133:8082/api";
// const API_URL = "http://localhost:8082/api";
const API_URL = "https://services.shifyt.com/api";

const axiosApi = axios.create({
  baseURL: API_URL,
});

function listner() {
  return JSON.parse(localStorage.getItem("authUser"))?.token;
  return store.getState()?.Login?.data?.access;
}
export default async function callApi(
  url,
  data = {},
  method = "GET",
  config = {}
) {
  let newURl = url;
  if (url?.startsWith("/")) {
    newURl = url?.replace("/", "");
  }
  return await axiosApi({
    method: method,
    url: newURl,
    data,
    headers: {
      Authorization: "Bearer " + listner(),
      ...config,
    },
    // ...config,
  })
    .then(res => {
      if (res.status === 401) {
        localStorage.removeItem("authUser");
      }
      if (res.status === 200 || res.status === 201) {
        // if (res?.data?.message?.length > 0) toast.success(res?.data?.message);
      }
      return { data: res.data, status: res.status };
    })
    .catch(error => {
      // console.log(error.response);
      if (error.response.status === 422) {
        toast.error(error.response?.data)
      }
      if (!error.response?.data?.message) {
        console.log(error.response,"error");
        if(error.response?.data?.data)
          toast.error(error.response.data.data);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error)
        }
        // throw new Error(error.response.data?.data)
      }else {
        console.log(error.response,"error");
        toast.error(error.response?.data.message);
      }  if (error.response.status === 500) {
        toast.error("Internal Server Error");
      } 
      if (error.response.status === 401) {
        localStorage.removeItem("authUser");
      }
    });
  
    // return await new Promise((resolve, reject) => {
    //   axiosApi({
    //     method: method,
    //     url: newURl,
    //     data,
    //     headers: {
    //       Authorization: "Bearer " + listner(),
    //       ...config,
    //     },
    //     // ...config,
    //   })
    //     .then(res => {
    //       if (res.status === 401) {
    //         localStorage.removeItem("authUser");
    //       }
    //       if (res.status === 200 || res.status === 201) {
    //         // if (res?.data?.message?.length > 0) toast.success(res?.data?.message);
    //       }
    //       resolve({ data: res.data, status: res.status });
    //     })
    //     .catch(error => {
    //       // console.log(error);
    //       if (error.response.status === 401) {
    //         localStorage.removeItem("authUser");
    //       }
    
    //       if (!error.response?.data?.message) {
    //         reject(error.response.data);
    //         // toast.error(error.response.data.data);
    //         // throw new Error(error.response.data?.data)
    //       } else if (error.request) {
    //         reject("Internal Server Error");
    //         // toast.error("Internal Server Error");
    //       } else {
    //         reject(error.response?.data.message);
    //         // toast.error(error.response?.data.message);
    //       }
    //     });
    // })
}
