import { Button } from "@mui/material";
import {
  PrimaryButton,
  PrimaryOutlinedButton,
  SecondaryButton,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { handleApproveJob } from "helpers/services/adminStaff/AccountManager.js/ApproveJobs";
import SocketConnection from "hooks/sockets";
import ViewMoreButton from "./ViewMoreButtons";
import { toast } from "react-hot-toast";

export default function PendingCardButtons({
  tableData,
  // setTableData,
  item,
  handleViewMore,
  setUpdate,
}) {
  // const { lastMessage, sendMessage: sendSocketMessage,connectionStatus } = SocketConnection();
  return (
    <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
      <PrimaryButton
        sx={{ width: "100px" }}
        startIcon={<FeatherIcon icon="check-circle" size="14" />}
        onClick={async () => {
          const { status, data: approvedData } = await handleApproveJob(
            item.id
          );
          if ([200,201].includes(status)) {
            setUpdate(pre=>!pre);
            toast.success("Successfully Approved!")
            // const index = tableData.findIndex(({ id }) => id === item.id);
            // const data = [...tableData];
            // data.splice(index, 1);
            // setTableData(data);
            // await sendSocketMessage(
            //   JSON.stringify({
            //     type: "verified_job",
            //     message: {
            //       data: "verified_job",
            //     },
            //     reciever_connectionId: "",
            //   })      
            // )       
          } else {
            ErrorHandlingToaster({ status, data: approvedData });
          }
        }}
      >
        Approve
      </PrimaryButton>
      <ViewMoreButton id={item?.id} />
    </div>
  );
}
