import { FormControl, Grid, IconButton, Stack } from "@mui/material";
import {
  BlackTypography,
  CustomSlider,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";

export default function HireIn() {
  const {
    register,
    getValues,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "question",
    }
  );

  console.log(fields);
  // useEffect(() => {
  //   console.log(  Math.round(
  //     (Number(watch("max_sal")) * Number(watch("refer_percentage"))) / 100
  //   ),"valll")
  //   setValue(
  //     "refer_amount",
  //     Math.round(
  //       (Number(watch("max_sal")) * Number(watch("refer_percentage"))) / 100
  //     )
  //   );
  // }, [watch("max_sal"), watch("refer_percentage")]);

  useEffect(() => {
    // console.log(watch("max_sal"),"nani")
    // const maxSal = (watch("max_sal"));
    // const referPercentage = Number(watch("refer_percentage"));

    // if ((maxSal) && (referPercentage)) {
    //  let num1 = parseInt(maxSal)
    //  let num2 = (referPercentage)
    //  let result = Math.round((parseInt(maxSal.replace(",","")) * num2) /100)

    //   const referAmount = result
    //   console.log("refer_amount:", referAmount);
    //   setValue("refer_amount", referAmount);
    // }

    const maxSal = watch("max_sal").replace(/,/g, ""); // Remove commas
    const referPercentage = Number(watch("refer_percentage"));

    if (maxSal && referPercentage) {
      const num1 = parseInt(maxSal);
      const num2 = referPercentage;
      const result = Math.round((num1 * num2) / 100);

      const referAmount = result;
      console.log("refer_amount:", referAmount);
      setValue("refer_amount", referAmount);
    }
  }, [watch("max_sal"), watch("refer_percentage")]);

  return (
    <Stack spacing={2} sx={{ margin: "20px 0px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="question-outlined"
              error={errors.question}
            >
              Screening Questions*
            </PrimaryNormalInputLabel>
            <Stack spacing={2} sx={{ marginTop: "20px" }}>
              {fields.map((field, index) => (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems={"center"}
                  key={field.id}
                >
                  <PrimaryNormalInputs
                    autoFocus
                    fullWidth
                    placeholder="Enter your question"
                    id="question-outlined"
                    multiline
                    label="question address"
                    type={"text"}
                    error={errors.question}
                    variant="outlined"
                    {...register(`question.${index}.value`, { required: true })}
                  />
                  <IconButton onClick={() => append()}>
                    <FeatherIcon icon="plus" size="14" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (index !== 0) remove(index);
                    }}
                  >
                    <FeatherIcon icon="minus" size="14" />
                  </IconButton>
                </Stack>
              ))}
            </Stack>

            <PrimaryNormalFormHelperText error={errors.question}>
              {errors?.question ? "screening question is a required field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <BlackTypography
            sx={{ fontWeight: "bold" }}
            variant="h3"
            component="h3"
          >
            {/* Hire in days, not Months */}
            Referral Commission*
          </BlackTypography>
        </Grid>
        <Grid item xs={12} md={3}>
          <PrimaryNormalInputs
            type="number"
            placeholder="refer amount"
            error={errors?.refer_amount}
            step=".01"
            {...register("refer_amount", { required: true })}
            value={parseInt(watch("refer_amount"))}
            onChange={e => {
              let value = e.target.value;
              setValue("refer_amount", value);
              setValue(
                "refer_percentage",
                (Number(value) / Number(watch("max_sal"))) * 100
              );
            }}
            aria-aria-label="refer-amount"
          ></PrimaryNormalInputs>

          {/* <Controller
  name="refer_percentage"
  control={control}
  defaultValue={initialReferPercentage} // Set the initial value here
  render={({ field }) => (
    <PrimaryNormalInputs
      type="number"
      placeholder="Refer Percentage"
      step=".01"
      error={errors?.refer_percentage}
      {...field}
      onChange={e => {
        field.onChange(e);
        const value = e.target.value;
        setValue(
          "refer_amount",
          Math.round((Number(watch("max_sal")) * Number(value)) / 100)
        );
      }}
      aria-label="refer-percentage"
    />
  )}
/> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <PrimaryNormalInputs
                step=".01"
                error={errors?.refer_percentage}
                type="number"
                placeholder="refer percentage"
                {...register("refer_percentage", {
                  required: true,
                  min: 8.3,
                  max: 100,
                })}
                value={watch("refer_percentage")}
                onChange={e => {
                  let value = e.target.value;
                  setValue("refer_percentage", value);
                }}
                aria-aria-label="refer-percentage"
              ></PrimaryNormalInputs>

              <div
                style={{
                  background: "rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  display: "grid",
                  placeItems: "center",
                  width: "48px",
                  height: "48px",
                }}
              >
                %
              </div>
              <BlackTypography variant="h5" component="h5">
                of Annual Salary
              </BlackTypography>
            </Stack>
            <PrimaryNormalFormHelperText error={errors?.refer_percentage}>
              {errors?.refer_percentage &&
                "refer percentage should be greater than 8.3"}
            </PrimaryNormalFormHelperText>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSlider
            step={0.01}
            {...register("refer_percentage", {
              required: true,
              min: 8.3,
              max: 100,
            })}
            value={watch("refer_percentage")}
            onChange={(e, value) => {
              setValue("refer_percentage", value);
            }}
            defaultValue={8.3}
            //   min={8.8}
            max={100}
            thumb__hover_color={`${
              errors?.refer_percentage
                ? "rgba(255,0,0,0.2)"
                : "rgba(79,195,247,0.5)"
            }`}
            backgrounds={`${errors?.refer_percentage ? "red" : "#4FC3F7"}`}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
