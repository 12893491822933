import { Chip, Divider, Stack } from "@mui/material";
import { findJobType } from "helpers/functions/findJobType";
import { formatDistance,isBefore } from "date-fns";
import getSymbolFromCurrency from "currency-symbol-map";
import { ACM, ZA } from "common/constants/userModules";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import CloseJobModel from "./closeJobModel";
import { Modal } from "@mui/material";
import React from "react";
import { deleteSingleJob } from "helpers/services/jobs/jobs";
import {
  GrayDivider,
  PrimaryChip,
  SecondaryFilledChip,
  StyledSecondaryTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { formatNumberWithCommas } from "helpers/functions/formatAmount";
import { Tooltip } from "@mui/material";

export default function JobCardInformation({
  item,
  tableData,
  // setTableData,
  setUpdate,
}) {

  const formattedDate = (deadLine,currentDate)=>{
    
    const formattedDeadline = formatDistance(deadLine, currentDate);

    const isExpired = isBefore(deadLine, currentDate);
    if(isExpired){
      let status = `has expired.`
      return status
    } else {
      let status = `${formattedDeadline} to apply`
      return status
    }

  }

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1}>
        <div className="d-flex align-items-center gap-1">
          <FeatherIcon icon="briefcase" size="14" />
          <StyledSecondaryTypography variant="h6" component="h6">
            {/* {item.min_exp}-{item.max_exp} years */}
            {`${item.min_exp}${
              item.min_exp_months > 0 ? `.${item.min_exp_months}` : ""
            }`}{" "}
            -{" "}
            {`${item.max_exp}${
              item.max_exp_months > 0 ? `.${item.max_exp_months}` : ""
            }`}
            years
          </StyledSecondaryTypography>
        </div>
        <GrayDivider orientation="vertical" flexItem />
        <div className="d-flex align-items-center gap-1">
          <StyledSecondaryTypography variant="h6" component="h6">
            {getSymbolFromCurrency(item.currency)}{" "}
            {/* {formatNumberWithCommas(item.min_sal)} -{" "} */}
            {Math.round(item.min_sal / 1000, 2)}k-{" "}
            {getSymbolFromCurrency(item.currency)}{" "}
            {/* {formatNumberWithCommas(item.max_sal)} */}
            {Math.round(item.max_sal / 1000, 2)}k
          
          </StyledSecondaryTypography>
        </div>
        <GrayDivider orientation="vertical" flexItem />
        <div className="d-flex align-items-center gap-1">
          <FeatherIcon icon="map-pin" size="14" />
          <Tooltip 
            placement="top"
            title={`${item.location}`}
          >
          <StyledSecondaryTypography variant="h6" component="h6">
            {item.work_mode !== "Fully_remote" ? item.location.length > 15 ? item.location.slice(0,10) +".." :item.location : "Remote"}
          </StyledSecondaryTypography>
          </Tooltip>
        </div>
      </Stack>
      <Stack direction="row" spacing={1}>
        <div className="d-flex align-items-center gap-1 position-relative">
          {item?.key_skill_details ? item?.key_skill_details?.slice(0, 4).map(data => (
            <StyledSecondaryTypography
              key={data?.id}
              variant="h6"
              component="h6"
              purpose="skills"
            >
              {data?.name}
            </StyledSecondaryTypography>
          )):"--"}
        </div>
      </Stack>
      <GrayDivider />
      <Stack direction="row" spacing={1} justifyContent={"space-between"}>
        <Tooltip
          placement="top"
          title={`${formatDistance(new Date(item.createdAt), new Date(), {
            // addSuffix: true,
          })} posted`}
        >
          <StyledSecondaryTypography variant="h6" component="h6">
            {formatDistance(new Date(item.createdAt), new Date(), {
              addSuffix: true,
            })}{" "}
            posted
          </StyledSecondaryTypography>
        </Tooltip>

        <Tooltip
          placement="top"
          title={`Applicants ${item?.get_applicants_count}`}
        >
          <StyledSecondaryTypography variant="h6" component="h6">
            Applicants ({item?.get_applicants_count})
          </StyledSecondaryTypography>
        </Tooltip>
        <Tooltip
          placement="top"
          // title={`${formatDistance(
          //   new Date(item.application_deadline),
          //   new Date(),
          //   {
              
          //   }
          // )} to apply`}

          title={formattedDate(new Date(item.application_deadline),new Date())}
        >
          <StyledSecondaryTypography variant="h6" component="h6">
            {/* {formatDistance(new Date(item.application_deadline), new Date(), {
            })}{" "} */}
            {formattedDate(new Date(item.application_deadline),new Date())}
            {/* to apply */}
          </StyledSecondaryTypography>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
