const { Container, Col } = require("reactstrap");
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import noData from "../../assets/images/zepul/shiyftEmpty.png";
import { connect } from "react-redux";
import JobCardInformation from "components/jobCard/jobCardInformations";
import JobPostedCompanyAvatar from "components/jobCard/JobPostedCompanyAvatar";
import { StyledCard, StyledCardContent } from "assets/Mui/globalTheme";
import { Stack } from "@mui/material";
import ApprovedCardsButton from "components/jobCard/ApproveCardButtons";
import {
  getAllJobs,
  getJobsBasedOnCompany,
  getSingleJobDetails,
} from "helpers/services/jobs/jobs";
import ErrorHandlingToaster from "helpers/Toaster/error";
import DetailsDrawer from "components/JobDetails/DetailDrawer";
import SearchParamHook from "hooks/searchParams";

export default function CompanyJobListForProfile() {
  let history = useHistory();
  const searchParams = SearchParamHook();
  const { id } = useParams();
  const [jobs, setJobs] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    dataCaller();
  }, []);
  async function dataCaller() {
    let flag = true;
    const { data, status } = await getJobsBasedOnCompany(id);
    ErrorHandlingToaster({ data, status });
    if (flag && status === 200) {
      setJobs(data);
      status ? setLoad(true) : "";
    }
    return () => {
      flag = false;
    };
  }
  const [tableData, setTableData] = useState([]);
  const [viewDetails, setViewDetails] = useState(tableData[0]);
  const [showDetails, setShowDetails] = useState(false);
  const [jobDetails, setJobDetails] = useState({
    application_deadline: new Date(),
    createdAt: new Date(),
  });
  useEffect(() => {
    const data = searchParams.get("id");
    if (data) {
      setShowDetails(true);
      findJobDetails(data);
    }
  }, [searchParams]);
  const toggleDrawer = React.useCallback(() => {
    setShowDetails(!showDetails);
    searchParams.delete("id");
    history.replace({
      search: searchParams.toString(),
    });
  }, [showDetails]);
  async function findJobDetails(id) {
    const { status, data } = await getSingleJobDetails(id);
    ErrorHandlingToaster({ data, status });
    setJobDetails(data);
  }
  useEffect(() => {
    const activeData = jobs?.filter(
      data => data.is_job_post_verified === true && data.is_active === true
    );
    setTableData(activeData);
  }, [jobs]);

  useEffect(() => {
    setViewDetails(tableData[0]);
  }, [tableData]);

  return (
    <>
      {load ? (
        <div>
          <Container
            fluid
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
          >
            <div className="row row-gap-4">
              {tableData.length > 0 ? (
                tableData.map(item => (
                  <Col xs={12} md={6} lg={4} className="mb-4" key={item.id}>
                    <StyledCard>
                      <StyledCardContent>
                        <Stack direction="column" spacing={1}>
                          <JobPostedCompanyAvatar item={item} />

                          <JobCardInformation
                            tableData={tableData}
                            setUpdate={() => {}}
                            setTableData={setTableData}
                            item={item}
                          />
                          <ApprovedCardsButton
                            tableData={tableData}
                            item={item}
                            setUpdate={() => {}}
                            setTableData={setTableData}
                            // handleViewMore={handleViewMore}
                          />
                        </Stack>
                      </StyledCardContent>
                    </StyledCard>
                  </Col>
                ))
              ) : (
                <div className="no_data_show">
                  <div>
                    <img src={noData} className="lg_img"></img>
                    <h4>No Active Jobs Found</h4>
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>
      ) : (
        <div className="loader_container" style={{ height: "80vh" }}>
          <div className="loader"></div>
        </div>
      )}
      <DetailsDrawer
        jobDetails={jobDetails}
        toggleDrawer={toggleDrawer}
        open={showDetails}
      />
    </>
  );
}
