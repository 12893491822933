const { Row, Container, Col, Card, CardBody } = require("reactstrap");
import {
  getAllEducationalQualifications,
  getAllEducationalSpecialization,
  updateEducationalSpecialization,
} from "helpers/services/jobAttributes/educationalQualifications";
import React from "react";
import { FormControlLabel, FormGroup } from "@mui/material";
import "../../../assets/css/jobattr.css";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";

import CreateEducationalQualification from "./createModel.js/create";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import { toast } from "react-hot-toast";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import {
  StageSearchInput,
  GreyTypography,
} from "assets/Mui/globalTheme";
import SearchIcon from "@mui/icons-material/Search";

class Specialization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      name: "",
      id:"",
      navSelected: "0",
      data: [],
      qualification: [],
      purpose: "",
      educational_qualification_id: "",
      id: "",
      name: "",
      openSearch:true,
      searchRole:""
    };
    this.toggle = this.toggle.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.updated = this.updated.bind(this);
  }
  componentDidMount() {
    this.updated();
  }

  async updated() {
    const qualification = await getAllEducationalQualifications();
    const items = qualification.map(item => {
      return { value: item.id, label: item.name };
    });
    const data = await getAllEducationalSpecialization();
    console.log(data,"qualificationData")
    this.setState({ data, qualification: items });
  }
  toggle(
    purpose = "",
    name = "",
    id = "",
    index = "",
    educational_qualification_id = ""
  ) {
    this.setState({
      purpose: purpose,
      name: name,
      index,
      id,
      isOpen: !this.state.isOpen,
      educational_qualification_id,
    });
  }

  handleAction(item, index) {
    let result = this.state.data;
    updateEducationalSpecialization(result[index].id, {
      educational_qualification_id: result[index].educational_qualification_id,
      name: result[index].name,
      is_active: !result[index].is_active,
    });
    // result[index].is_active = !result[index].is_active;

    const filterOne = result?.map((each)=>{
      if(each.id == item.id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

     const statusCheck = result?.filter((each)=>{
      if(each?.id == item.id){
        return {
          ...each,
          is_active:!item.is_active
        }
      }
     })

    this.setState({
      data: filterOne,
    });

  
    if(statusCheck && statusCheck[0]?.is_active){
      toast.error("disabled")
   
    }else {
      toast.success("enabled")
    }

    // if (result[index].is_active) {
    //   toast.success("enabled")
  
    // } else {
    //   toast.error("disabled")
 
    // }


  }

    /* Search toggle effect */
    handleSearchOpen = () => {
      this.setState((prevState) => ({
        openSearch: !prevState.openSearch,
        searchRole:""
      }));
    }

    handleSearch=(e)=>{
      this.setState((prevState)=>({
        searchRole:e.target.value
      }))
    }

    searchFilterData = (data)=>{
      const searchWord = this.state?.searchRole?.toLowerCase()
      const searchPattern = data?.filter((item)=>{
        const roleName = item?.name?.toLowerCase()
        const categoryName = item?.job_attribute_functional_area?.name?.toLowerCase()
    
        if(roleName?.includes(searchWord) || categoryName?.includes(searchWord)){
          return item
        }
      })
      return searchPattern
    }
    
    /* Search toggle effect end */

  render() {
    const filteredData = this.searchFilterData(this.state?.data)
    
    return (
      <Container fluid>
        <CreateEducationalQualification
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          qualification={this.state.qualification}
          specData={this.state.data}
          purpose={this.state.purpose}
          name={this.state.name}
          id={this.state.id}
          updated={this.updated}
          index={this.state.index}
          educational_qualification_id={this.state.educational_qualification_id}
        />

        {/* end */}
        <Row>
          <Row className="align-items-center ">
            <Col md={12} lg={12} style={{ padding: "40px" }} className="header_jobs_sticky">
              <PrimaryButton
                sx={{
                  width: "245px",
                }}
                onClick={() => this.toggle("Create")}
              >
                Create Educational Specialization
              </PrimaryButton>

              <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
                  {this.state.openSearch ? (
                    <SearchIcon
                      onClick={this.handleSearchOpen}
                    
                    />
                  ) : (
                    <StageSearchInput
                      placeholder="search..."
                      onChange={(e)=>this.handleSearch(e)}
                      endAdornment={
                        <IconButton edge="end" onClick={this.handleSearchOpen}>
                          <ClearIcon />
                         </IconButton>
                      }
                    />
                  )}
                
                </Stack>

            </Col>
          </Row>
          <div className="table_cont">
            <div className="table-responsive">
              <Card style={{ maxWidth: "80vw" }}>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Qualification</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {/* {this.state.data.map((item, index) => ( */}
                      {filteredData?.map((item, index) => (
                        <TableRow key={item.name + index}>
                          <TableCell>{item.name}</TableCell>

                          <TableCell className="active_td">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <PrimarySwitch
                                    sx={{ m: 1 }}
                                    checked={item.is_active}
                                    onChange={async e => {
                                      this.handleAction(item, index);
                                    }}
                                  />
                                }
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell className="col_btn">
                            <PrimaryButton
                              sx={{ width: "100px" }}
                              onClick={() =>
                                this.toggle(
                                  "edit",
                                  item.name,
                                  item.id,
                                  index,
                                  item.educational_qualification_id
                                )
                              }
                            >
                              Edit
                            </PrimaryButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default Specialization;
