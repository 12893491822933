import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";
import { connect, useSelector } from "react-redux";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { getAllNotifications } from "helpers/services/Notifications/NotificationApi";
import {
  BlackTypography,
  SearchPrimaryInput,
  GreyTypography,
  StageSearchInput,
} from "assets/Mui/globalTheme";
import {
  Avatar,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { blue, grey, indigo } from "@mui/material/colors";
import { imageURL } from "common/constants/commonURLS";
import ProfilPopOver from "./profilePopover";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import SearchParamHook from "hooks/searchParams";
import { useHistory } from "react-router-dom";
import EditProfile from "components/profile/editProfile";
import { findLoggedInUser } from "helpers/services/CommonUser/findUserbasedOnType";
import ClearIcon from "@mui/icons-material/Clear";
import NotificationPopOver from "./NotificationPopOver";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 100,
      background: "#e6e8ed",
      borderRadius: "10px",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    },
  },
};
const names = ["ALL", "TE", "SP", "RP", "BVC"];

/* Notification data */
const notificationsData = [
  {
    id: 1,
    title: "Syoft",
    role: "Python Developer",
    image:
      "https://media.licdn.com/dms/image/C560BAQEbAYjuSygMKg/company-logo_200_200/0/1660810909959?e=2147483647&v=beta&t=Bhh0lgAd_vwQlag3vizAsO1iVINEhmIq-OEM67L5Rkk",
    time: "Thu,23 2023",
    message: "Your profile was",
    key: "Submitted",
    type: "msg",
  },
  {
    id: 2,
    title: "Tata Consultancy Services",
    role: "NodeJs Developer",
    image:
      "https://cabinetm-beta.s3.amazonaws.com/0000017a-d348-a35a-a0e3-37aaa6dbde4b.jpg",
    time: "Mon,12 2022",
    message: "Your profile was",
    key: "Selected",
    type: "msg",
  },
  {
    id: 3,
    title: "Wipro Limited",
    role: " AWS Cloud Architect",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/a/a0/Wipro_Primary_Logo_Color_RGB.svg",
    time: "Fri,16 2021",
    message: "Your profile was",
    key: "Rejected",
    type: "msg",
  },
  {
    id: 4,
    title: "Virtusa",
    role: "Angular Developer",
    image:
      "https://content.jdmagicbox.com/comp/pune/l5/020pxx20.xx20.180926190951.p1l5/catalogue/virtusa-pune-pune-corporate-companies-lk0bik5jhd-250.jpg",
    time: "Tue,28 2023",
    message: "Your have received a new message from",
    key: "Dileep",
    type: "chat",
  },
];
/*Notifications data */



export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationEl, setNotificationEl] = React.useState(null);
  const searchParams = SearchParamHook();
  const [model, setModel] = useState(false);
  const [notificationsModel, setNotificationModel] = React.useState(false);
  const [userDetails, setUserDetails] = useState({});
  const role = JSON.parse(localStorage.getItem("authUser")).role;
  const [search, setSearch] = React.useState(searchParams.get("q") || "");

  // const [notificationsData,setNotificationData] = React.useState([])

  const history = useHistory();
  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
    } else {
      setSearch("");
    }
  }, [searchParams]);
  useEffect(() => {
    if (role.includes("CS")) {
      (async () => {
        const getUserDetails = await findLoggedInUser();
        setUserDetails(getUserDetails);
      })();
    }
  }, []);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleBellClick = event => {
    setNotificationEl(event.currentTarget);
  };

  const handleBellClose = () => {
    setNotificationEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [searchValue, setSearchType] = React.useState([]);

  const user = useSelector(state => state?.Login);

  const handleChange = event => {
    const {
      target: { value },
    } = event;

    setSearchType(typeof value === "string" ? value.split(",") : value);
  };
  function handleSearch(e) {
    setSearch(e.target.value);
    searchParams.set("q", e.target.value);
    history.replace({
      search: searchParams.toString(),
    });
  }
  const companyName = JSON.parse(
    localStorage.getItem("authUser")
  )?.company_name;


  /* Notification fetch */
// React.useEffect(async()=>{
//   const notifyData= await getAllNotifications()
//   setNotificationData(notifyData)
// },[])
/* Notification fetch */

  return (
    <header className="header_sticky">
      <div className="d-flex align-items-center justify-content-between py-2 px-4 ">
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <IconButton onClick={() => history.goBack()}>
            <FeatherIcon
              icon="chevron-left"
              size="20"
              className="kanban_left_icon"
            />
          </IconButton>
          <IconButton onClick={() => history.goForward()}>
            <FeatherIcon
              icon="chevron-right"
              size="20"
              className="kanban_left_icon"
            />
          </IconButton>
          {/* <Paper
            component="form"
            onSubmit={e => {
              e.preventDefault();
              searchParams.set("q", search);
              searchValue.map(item => searchParams.append("type", item));
              history.replace({
                pathname: "/search",
                search: searchParams.toString(),
              });
            }}
            elevation={0}
            sx={{
              background: "rgba(8,23,83,0.1)",
              p: "2px 4px",
              display: "flex",
              borderRadius: "20px",
              alignItems: "center",
              width: 400,
            }}
          >
            <FormControl sx={{ width: 80, marginLeft: "20px" }}>
              <Select
                multiple
                sx={{ height: 30 }}
                displayEmpty
                value={searchValue}
                onChange={handleChange}
                input={<InputBase />}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return <span>All</span>;
                  }
                  return selected?.join(",");
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                {names.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <InputBase
              onChange={handleSearch}
              sx={{ ml: 1, flex: 1 }}
              value={search}
              placeholder="Search here"
              inputProps={{ "aria-label": "search here" }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              component="button"
              type="submit"
              sx={{ p: "10px" }}
              aria-label="directions"
            >
              <FeatherIcon icon="search" size={"14"} />
            </IconButton>
          </Paper> */}
        </Stack>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <IconButton>
            <FeatherIcon icon="help-circle" size="18"></FeatherIcon>
          </IconButton>
          <IconButton onClick={handleBellClick}>
            {/* <FeatherIcon icon="bell" size="18"></FeatherIcon> */}

            <Badge badgeContent={4} color="success">
              <NotificationsNoneIcon color="action" />
            </Badge>
          </IconButton>

          <Stack onClick={handleClick} alignItems={"flex-end"}>
            <BlackTypography variant="h4" component="h4">
              {user?.usertype?.replace("_", " ")?.toLowerCase()}
            </BlackTypography>

            <GreyTypography variant="h6" component="h6">
              {user?.first_name} {user?.last_name}
            </GreyTypography>

            {user?.usertype == "COMPANY_STAFF" ? (
              <BlackTypography variant="h4" component="h4">
                {/* {user?.company_details?.company_name} */}
                {companyName}
              </BlackTypography>
            ) : (
              ""
            )}
          </Stack>
          <Avatar
            sx={{
              height: 32,
              width: 32,
              bgcolor: "#cde2e7",
              color: "#024430",
            }}
            onClick={handleClick}
            alt="user Profile"
            src={`${imageURL}${user?.image}`}
          >
            {user?.first_name?.charAt(0)?.toUpperCase()}
          </Avatar>
          <IconButton onClick={handleClick}>
            <FeatherIcon
              icon={!anchorEl ? "chevron-down" : "chevron-up"}
              size="14"
            />
          </IconButton>
        </Stack>
        <ProfilPopOver
          handleClose={handleClose}
          viewModel={() => setModel(true)}
          anchorEl={anchorEl}
        />

        <NotificationPopOver
          handleBellClose={handleBellClose}
          viewModel={() => setNotificationModel(true)}
          notificationEl={notificationEl}
          notificationsData={notificationsData}
        />
      </div>
      <EditProfile
        open={model}
        toggleModel={() => setModel(false)}
        user={userDetails}
      />
    </header>
  );
}
