const { Row, Container, Col, Card, CardBody } = require("reactstrap");
import React from "react";
import { IOSSwitch } from "components/Common/iosswitch";
import JobModel from "../../components/Common/models/jobRole";
import SearchIcon from "@mui/icons-material/Search";
import {
  createJobLevel,
  deleteJobLevel,
  getAllJobLevlsWithoutFunctionalArea,
  updateJobLevel,
} from "../../helpers/services/jobAttributes/jobLevels";
import { toast } from "react-hot-toast";
import { FormControlLabel, FormGroup } from "@mui/material";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import {
  StageSearchInput,
  GreyTypography,
} from "assets/Mui/globalTheme";

class JobRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      name: "",
      id:"",
      data: [],
      purpose: "",
      functional_area_id: "",
      isLoading: "true",
      openSearch:true,
      searchRole:""
    };
    this.toggle = this.toggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.getAllJobLevlsWithoutFunctionalArea =
      getAllJobLevlsWithoutFunctionalArea.bind(this);
    this.handlRestore = this.handlRestore.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const data = await this.getAllJobLevlsWithoutFunctionalArea();
    this.setState({ data: data,isLoading: false });
  }

  async componentDidUpDate(_, prevState) {
    if (prevState.data !== this.state.data) {
    }
    if (prevState.data.length !== prevState.data.length) {
      const data = await this.getAllJobLevlsWithoutFunctionalArea();
      this.setState({ data: data });
    }
  }

    /* Search toggle effect */
    handleSearchOpen = () => {
      this.setState((prevState) => ({
        openSearch: !prevState.openSearch,
        searchRole:""
      }));
    }

    handleSearch=(e)=>{
      this.setState((prevState)=>({
        searchRole:e.target.value
      }))
    }

    searchFilterData = (data)=>{
      const searchWord = this.state?.searchRole?.toLowerCase()
      const searchPattern = data?.filter((item)=>{
        const roleName = item?.name?.toLowerCase()
        const categoryName = item?.job_attribute_functional_area?.name?.toLowerCase()
    
        if(roleName?.includes(searchWord) || categoryName?.includes(searchWord)){
          return item
        }
      })
      return searchPattern
    }
    
    /* Search toggle effect end */


  toggle(
    purpose = "",
    name = "",
    active = "",
    index = "",
    id = "",
    functional_area_id = ""
  ) {
    this.setState({
      purpose: purpose,
      name: name,
      index,
      functional_area_id: functional_area_id,
      active: active,
      id: id,
      isOpen: !this.state.isOpen,
    });
  }
  handleDelete(index, id) {
    deleteJobLevel(id);
    let result = this.state.data;
    // result[index].is_active = !result[index].is_active;

    const filterOne = result?.map((each)=>{
      if(each.id == id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    this.setState({
      data: filterOne,
    });
    toast.error("Disabled")
  }

  async updateFunc(sample, type, index,id) {

    let editedId;
    if (id) {
      editedId = id;
    } else {
      editedId = this.state.id;
    }

    if (type === "create") {
      await createJobLevel(sample);
      this.setState({ isOpen: false });
      this.setState({
        data: [...this.state?.data, sample],
      });
      toast.success("created")

      this.setState({ isLoading: true });
      const data = await this.getAllJobLevlsWithoutFunctionalArea();
      this.setState({ data: data,isLoading: false });

    } else {
      // await updateJobLevel(sample, this.state.id, sample.functional_area_id);
      await updateJobLevel(sample, editedId, sample.functional_area_id);
      toast.success(" Updated")
      let result = this.state.data;

      const filterOne = result?.map((each)=>{
        if(each.id == editedId){
          return {
            ...each,
            name: sample.name,
            is_active: sample.is_active,
          }

        }else {
          return {
            ...each
          }
        }
       })

      // result[index] = sample;
      this.setState({
        data: filterOne,
        isOpen: false,
      });
    }
  }
  handlRestore(item, index, fnId) {
    const sample = {
      name: item.name,
      is_active: !item.is_active,
    };
    updateJobLevel(sample, item.id, fnId);
    let result = this.state.data;
    // result[index].is_active = !result[index].is_active;

    const filterOne = result?.map((each)=>{
      if(each.id == item.id){
        return {
          ...each,
          name:item?.name,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    this.setState({
      data: filterOne,
    });
    toast.success("Enabled")
  }
  handleAction(item, index) {
    item.is_active
      ? this.handleDelete(index, item.id)
      : this.handlRestore(item, index, item.functional_area_id);
  }
  render() {
    const filteredData = this.searchFilterData(this.state?.data)
    return (
      <Container fluid>
        <JobModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Job Role"}
          purpose={this.state.purpose}
          index={this.state.index}
          fn_id={this.state.functional_area_id}
          title={this.state.name}
          active={this.state.active}
          update={this.updateFunc}
          id={this.state.id}
        />
        <div className="header_jobs_sticky" md={6} style={{ padding: "40px" }}>
          <PrimaryButton
            sx={{ width: "150px" }}
            onClick={() => this.toggle("create")}
          >
            Create Job Role
          </PrimaryButton>
          <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
                  {this.state.openSearch ? (
                    <SearchIcon
                      onClick={this.handleSearchOpen}
                    
                    />
                  ) : (
                    <StageSearchInput
                      placeholder="search..."
                      onChange={(e)=>this.handleSearch(e)}
                      endAdornment={
                        <IconButton edge="end" onClick={this.handleSearchOpen}>
                          <ClearIcon />
                        </IconButton>
                      }
                    />
                  )}
                
                </Stack>
        </div>
        <Row style={{ marginLeft: "20px" }}>
          <div className="table_cont">
            <div className="table-responsive">
              <Card>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Job Role</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                     

              {this.state.isLoading ? (
                  <BubbleLoader />
                  ) : (
                    // this.state?.data?.map((item, index) => (
                      filteredData?.map((item, index) => (
                      <TableRow key={item.name + index}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item?.job_attribute_functional_area?.name}</TableCell>
                        <TableCell className="active_td">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <PrimarySwitch
                                  sx={{ m: 1 }}
                                  checked={item.is_active}
                                  onChange={async e => {
                                    this.handleAction(item, index);
                                  }}
                                />
                              }
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell className="col_btn">
                          <PrimaryButton
                            sx={{ width: "100px" }}
                            onClick={() =>
                              this.toggle(
                                "edit",
                                item.name,
                                // item.default,
                                item.is_active,
                                index,
                                item.id,
                                item.functional_area_id
                              )
                            }
                          >
                            Edit
                          </PrimaryButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}

                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default JobRole;
