const { Row, Container, Col, CardBody, Card } = require("reactstrap");
import { updateSingleAdminStaffDetails } from "helpers/services/adminStaff/adminStaff";
import {
  FormControlLabel,
  FormGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  createIndustries,
  deleteIndustries,
  getAllIndustries,
  updateIndustries,
} from "helpers/services/jobAttributes/industries";
import React from "react";
import { toast } from "react-hot-toast";
import CommonModel from "../../components/Common/models/createModel";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import {
  createActualJobLevels,
  getAllActualJobLevel,
  updateActualJobLevel,
} from "helpers/services/jobAttributes/actualJobLevel";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import {
  StageSearchInput,
  GreyTypography,
} from "assets/Mui/globalTheme";

class JobLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      name: "",
      id:"",
      data: [],
      isLoading: "true",
      openSearch:true,
      searchRole:""
    };
    this.toggle = this.toggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.createActualJobLevels = createActualJobLevels.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handlRestore = this.handlRestore.bind(this);
  }
  async componentDidMount() {
    this.setState({ isLoading: true });
    const { data } = await getAllActualJobLevel();
    this.setState({ data, isLoading: false });
  }

  toggle(purpose = "", name = "", active = "", id = "", index) {
    this.setState({
      purpose: purpose,
      name: name,
      index,
      id,
      active: active,
      isOpen: !this.state.isOpen,
    });
  }
  handleDelete(index, id, name) {
    updateActualJobLevel({ is_active: false, name: name }, id);
    let result = this.state.data;
    // result[index].is_active = !result[index].is_active;

    const filterOne = result?.map((each)=>{
      if(each.id == id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    this.setState({
      data: filterOne,
    });
    toast.error("Disabled");
  }
  handlRestore(item, index) {
    const sample = {
      name: item.name,
      is_active: !item.is_active,
    };
    updateActualJobLevel(sample, item.id);
    let result = this.state.data;
    // result[index].is_active = !result[index].is_active;

    const filterOne = result?.map((each)=>{
      if(each.id == item.id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    this.setState({
      data: filterOne,
    });
    toast.success("Enabled");
  }
  handleAction(item, index) {
    item.is_active
      ? this.handleDelete(index, item.id, item.name)
      : this.handlRestore(item, index);
  }
  async updateFunc(sample, type, index,id) {
    let editedId;
    if (id) {
      editedId = id;
    } else {
      editedId = this.state.id;
    }

    if (type === "create") {
      await this.createActualJobLevels(sample);
      this.setState({
        data: [...this.state?.data, sample],
      });
      toast.success("Created")
      const { data } = await getAllActualJobLevel();
      this.setState({ data });
    } else {
      // await updateActualJobLevel(sample, this.state.id);
      const changeData = {
        name: sample?.name,
        is_active: sample?.is_Active,
      }
      await updateActualJobLevel(changeData, editedId);
      let result = this.state.data;
      // result[index] = sample;

      const filterOne = result?.map((each)=>{
        if(each.id == editedId){
          return {
            ...each,
            name:sample.name,
            is_active:sample.is_active
          }
        }else {
          return {
            ...each
          }
        }
       })

      this.setState({
        data: filterOne,
      });
      const { data } = await getAllActualJobLevel();
      this.setState({ data });
    }
  }

   /* Search toggle effect */
   handleSearchOpen = () => {
    this.setState((prevState) => ({
      openSearch: !prevState.openSearch,
      searchRole:""
    }));
  }

  handleSearch=(e)=>{
    this.setState((prevState)=>({
      searchRole:e.target.value
    }))
  }

  searchFilterData = (data)=>{
    const searchWord = this.state?.searchRole?.toLowerCase()
    const searchPattern = data?.filter((item)=>{
      const roleName = item?.name?.toLowerCase()
    
      if(roleName?.includes(searchWord)){
        return item
      }
    })
    return searchPattern
  }
  
  /* Search toggle effect end */

  render() {
    const filteredData = this.searchFilterData(this.state?.data)
    return (
      <>
        <CommonModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Job Levels"}
          purpose={this.state.purpose}
          index={this.state.index}
          title={this.state.name}
          active={this.state.active}
          update={this.updateFunc}
          id={this.state.id}
        />
        <div md={6} style={{ padding: "40px" }} className="header_jobs_sticky">
          <PrimaryButton
            sx={{ width: "200px" }}
            onClick={() => this.toggle("create")}
          >
            Create Job Levels
          </PrimaryButton>
          <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
                  {this.state.openSearch ? (
                    <SearchIcon
                      onClick={this.handleSearchOpen}
                    
                    />
                  ) : (
                    <StageSearchInput
                      placeholder="search..."
                      onChange={(e)=>this.handleSearch(e)}
                      endAdornment={
                        <IconButton edge="end" onClick={this.handleSearchOpen}>
                          <ClearIcon />
                        </IconButton>
                      }
                    />
                  )}
                
                </Stack>
        </div>
        <Row style={{ marginLeft: "20px" }}>
          <div className="table_cont">
            <div className="table-responsive">
              <Card style={{ maxWidth: "80vw" }}>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Job Levels</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {this.state.isLoading ? (
                        <BubbleLoader />
                      ) : (
                        // this.state.data.map((item, index) => (
                          filteredData?.map((item, index) => (
                          <TableRow key={item.name + index}>
                            <TableCell>{item.name}</TableCell>

                            <TableCell className="active_td">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <PrimarySwitch
                                      sx={{ m: 1 }}
                                      checked={item.is_active}
                                      onChange={async e => {
                                        this.handleAction(item, index);
                                      }}
                                    />
                                  }
                                />
                              </FormGroup>
                            </TableCell>
                            <TableCell className="col_btn">
                              <PrimaryButton
                                sx={{ width: "100px" }}
                                onClick={() =>
                                  this.toggle(
                                    "edit",
                                    item.name,
                                    item.active,
                                    item.id,
                                    index
                                  )
                                }
                              >
                                Edit
                              </PrimaryButton>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </>
    );
  }
}

export default JobLevel;
