const { Row, Container, Col, CardBody, Card } = require("reactstrap");
import {
  FormControlLabel,
  FormGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { toast } from "react-hot-toast";
import React from "react";
import CommonModel from "../../components/Common/models/createModel";
import {
  createAndEditFunctionalArea,
  deleteFunctionalArea,
  getAllFunctionalArea,
} from "../../helpers/services/jobAttributes/functionalArea";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import { StageSearchInput, GreyTypography } from "assets/Mui/globalTheme";

class FunctionalArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      id:"",
      name: "",
      data: [],
      isLoading: "true",
      openSearch: true,
      searchRole: "",
    };
    this.toggle = this.toggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.createAndEditFunctionalArea = createAndEditFunctionalArea.bind(this);
    this.getAllFunctionalArea = getAllFunctionalArea.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handlRestore = this.handlRestore.bind(this);
  }
  async componentDidMount() {
    this.setState({ isLoading: true });
    const items = await this.getAllFunctionalArea();
    this.setState({ data: items, isLoading: false });
  }

  toggle(
    purpose = "",
    id = "",
    name = "",
    defaultValue = "",
    active = "",
    index
  ) {
    // this.setState({
    //   purpose: purpose,
    //   id,
    //   name: name,
    //   index,
    //   default: defaultValue,
    //   active: active,
    //   isOpen: !this.state.isOpen,
    // });

    this.setState(prev => ({
      ...prev,
      purpose: purpose,
      name: name,
      index: index,
      id: id,
      default: defaultValue,
      active: active,
      isOpen: !this.state.isOpen,
    }));

  }
  handleDelete(index, id) {
    deleteFunctionalArea(id);
    let result = this.state.data;

    const filterOne = result?.map((each)=>{
      if(each.id == id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    // result[index].is_active = !result[index].is_active;
    this.setState({
      data: filterOne,
    });
    toast.error("Disabled");
  }
  handlRestore(item, index) {
    const sample = {
      name: item.name,
      active: !item.is_active,
    };
    createAndEditFunctionalArea(sample, "Edit", index, item.id);
    let result = this.state.data;

 
    const filterOne = result?.map((each)=>{
      if(each.id == item.id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    // result[index].is_active = !result[index].is_active;
    this.setState({
      data: filterOne,
    });
    toast.success("Enabled");
  }
  handleAction(item, index) {
    item.is_active
      ? this.handleDelete(index, item.id)
      : this.handlRestore(item, index);
  }
  updateFunc(sample, type, index,id) {

    let editedId;
    if (id) {
      editedId = id;
    } else {
      // editedId = this.state.id;
      editedId = sample?.id
    }
    console.log(editedId,"editedId")

   
    this.createAndEditFunctionalArea(sample, type, index, editedId);

    // this.createAndEditFunctionalArea(sample, type, index, this.state.id);
  }

  /* Search toggle effect */
  handleSearchOpen = () => {
    this.setState(prevState => ({
      openSearch: !prevState.openSearch,
      searchRole:""
    }));
  };

  handleSearch = e => {
    this.setState(prevState => ({
      searchRole: "",
      searchRole: e.target.value,
    }));
  };

  searchFilterData = data => {
    const searchWord = this.state?.searchRole?.toLowerCase();
    const searchPattern = data?.filter(item => {
      const roleName = item?.name?.toLowerCase();

      if (roleName?.includes(searchWord)) {
        return item;
      }
    });
    return searchPattern;
  };

  /* Search toggle effect end */

  render() {
    const filteredData = this.searchFilterData(this.state?.data);
    return (
      <Container fluid>
        <CommonModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Category"}
          purpose={this.state.purpose}
          index={this.state.index}
          title={this.state.name}
          defaultValue={this.state.default}
          active={this.state.active}
          update={this.updateFunc}
          id={this.state.id}
        />
        <div md={6} style={{ padding: "40px" }} className="header_jobs_sticky">
          <PrimaryButton
            // className="header_jobs_sticky"
            sx={{ width: "200px" }}
            onClick={() => this.toggle("create")}
          >
            Create Category
          </PrimaryButton>
          <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
            {this.state.openSearch ? (
              <SearchIcon onClick={this.handleSearchOpen} />
            ) : (
              <StageSearchInput
                placeholder="search..."
                onChange={e => this.handleSearch(e)}
                endAdornment={
                  <IconButton edge="end" onClick={this.handleSearchOpen}>
                    <ClearIcon />
                  </IconButton>
                }
              />
            )}
          </Stack>
        </div>
        <Row style={{ marginTop: "", marginLeft: "20px" }}>
          <Row className="align-items-center "></Row>
          <div className="table_cont">
            <div className="table-responsive">
              <Card>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Categories</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {this.state.isLoading ? (
                        <BubbleLoader />
                      ) : (
                        // this.state.data.map((item, index) => (
                        filteredData?.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>

                            <TableCell className="active_td">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <PrimarySwitch
                                      sx={{ m: 1 }}
                                      checked={item.is_active}
                                      onChange={async e => {
                                        this.handleAction(item, index);
                                      }}
                                    />
                                  }
                                />
                              </FormGroup>
                            </TableCell>
                            <TableCell className="col_btn">
                              <PrimaryButton
                                style={{ width: "100px" }}
                                onClick={() =>
                                  this.toggle(
                                    "edit",
                                    item.id,
                                    item.name,
                                    item.default,
                                    item.is_active,
                                    index
                                  )
                                }
                              >
                                Edit
                              </PrimaryButton>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default FunctionalArea;
