import * as React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import {
  PrimaryButton,
  PrimaryDataGrid,
  PrimarySwitch,
  SmallPrimaryAvatar,
  SmallSizeChip,
} from "assets/Mui/globalTheme";
import TablePopper from "components/table/header/popper";
import { IndustryOperators } from "components/table/operators/industryOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import LoaderIcon from "utils/icons/loaderIcon";
import {
  getAllCompanies,
  getAllCompaniesWithStatus,
} from "helpers/services/companies/company";
import { Link, useHistory } from "react-router-dom";
import { StyledGridOverlay } from "assets/Mui/globalTheme";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import { ZA } from "common/constants/userModules";
import SkillViewModal from "helpers/Modals/SkillViewModal";

export default function CompanyTable() {
  const history=useHistory()
  const [screeningPartners, setScreeningPartners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openSkillModal, setOpenSkillModal] = React.useState(false);
  const [modalName, setModalName] = React.useState("");
  const loginStore = JSON.parse(localStorage.getItem("authUser"));
  
  React.useEffect(() => {
    initialFetch();
  }, []);

  async function initialFetch() {
    setLoading(true);
    try {
      let {data } = await getAllCompaniesWithStatus();
      const companies = data.filter(item => {
        return item.company_user_details.usertype === "COMPANY";
      });
  
      const sorted = await companies.filter(item => {
        if (item.related_to_admin === false) return item;
      });
      const result = sorted?.map(item => ({
        //TODO if logo is coming without url change it else okay
        id: item?.id,
        image: item?.company_image_details,
        name: item?.company_name,
        company_id: item?.company_user_details?.id,
        location: item?.location,
        email: item?.company_user_details?.email,
        phone_number: item?.company_user_details?.phone_number,
        // functional_area: item?.functional_area_details
        //   ?.map(item => item?.name)
        //   ?.join(","),
        // industry: item?.industry_details?.map(item => item?.name)?.join(","),
        employees_count: item?.size_of_company,
        about: item?.about?item?.about:"N/A",
        // active_job_openings: item?.active_job_openings,
        total_job_posts: item?.company_jobs_overall_count,
        closed_job_posts: item?.company_closed_jobs_count,
        active_job_openings: item?.company_job_openings_count,
        // onboarded_staff: item?.onboarded_staff,
        // no_of_staff: item?.no_of_staff,
        // acm: item?.acm?item.acm:"N/A",
        // acm_email: item?.account_manager_details?.email ? item?.account_manager_details?.email : "N/A",
        acm_email: `${
          item?.account_manager_details?.length > 0
            ? item?.account_manager_details?.map(item => item?.email)?.join(",")
            : "N/A"
        }`,
        moderator_email: `${
          item?.moderator_details?.length > 0
            ? item?.moderator_details?.map(item => item?.email)?.join(",")
            : "N/A"
        }`,te_mail: `${
          item?.evaluator_details?.length > 0
            ? item?.evaluator_details?.map(item => item?.email)?.join(",")
            : "N/A"
        }`,sp_email: `${
          item?.screening_partner_details?.length > 0
            ? item?.screening_partner_details?.map(item => item?.email)?.join(",")
            : "N/A"
        }`,rc_email: `${
          item?.rc_details?.length > 0
            ? item?.rc_details?.map(item => item?.email)?.join(",")
            : "N/A"
        }`,bgv_email: `${
          item?.bgv_details?.length > 0
            ? item?.bgv_details?.map(item => item?.email)?.join(",")
            : "N/A"
        }`,
      }));
      setScreeningPartners(result);      
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
    // setScreeningPartners(result);
  }

  function handleCellClick(params) {
    const field = params.field
    console.log(field=== "acm_email",field);
    if (field.trim() === "acm_email") {
      console.log(params.row.acm_email !== "N/A",params.row.acm_email);
      if (params.row.acm_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.acm_email.split(","));
        setOpenSkillModal(!openSkillModal); 
      }
      return
    }
    if (field === "moderator_email") {
      if (params.row.moderator_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.moderator_email.split(","));
        setOpenSkillModal(!openSkillModal); 
      }
      return
    }
    if (field === "te_email") {
      if (params.row.te_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.te_email.split(","));
        setOpenSkillModal(!openSkillModal); 
      }
      return
    }
    if (field === "sp_email") {
      if (params.row.sp_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.sp_email.split(","));
        setOpenSkillModal(!openSkillModal); 
      }
      return
    }
    if (field === "rc_email") {
      if (params.row.rc_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.rc_email.split(","));
        setOpenSkillModal(!openSkillModal); 
      }
      return
    }
    if (field === "bgv_email") {
      if (params.row.bgv_email !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.bgv_email.split(","));
        setOpenSkillModal(!openSkillModal); 
      }
      return
    }
    else {
      if ([ZA].includes(loginStore.role))
        history.push(`../company/${params?.row?.company_id}`);  
    }
    
  }

  const handleCloseModal = () => {
    setOpenSkillModal(!openSkillModal);
  };
  return (
    <div style={{ padding: "10px", height: "91vh" }}>
      <PrimaryDataGrid
        loading={loading}
        onCellClick={handleCellClick}
        disableRowSelectionOnClick={true}
        checkboxSelection={true}
        columns={[
          {
            field: "image",
            headerName:"Image",
            filterable: false,
            renderCell: params => {
              return (
                <SmallPrimaryAvatar
                  imgProps={{ loading: "lazy" }}
                  src={params?.formattedValue}
                >
                  {params?.row?.name?.charAt(0)?.toUpperCase()}
                </SmallPrimaryAvatar>
              );
            },
          },
          {
            field: "name",
            headerName:"Name",
            width: 200,
            // renderCell: params => {
            //   return (
            //     <Link to={`../company/${params?.row?.company_id}`}>
            //       {params?.formattedValue}
            //     </Link>
            //   );
            // },
          },
          { field: "phone_number",headerName:"Phone Number", width: 300 },
          { field: "email",headerName:"Email", width: 300 },
          { field: "location",headerName:"Location", filterOperators: LocationOperators, width: 400 },
          // { field: "industry",headerName:"Industry", filterOperators: IndustryOperators, width: 400 },
          // { field: "functional_area", width: 200 },
          { field: "employees_count",headerName:"Company Size",width: 200 },
          { field: "about",headerName:"About", width: 400 },
          { field: "active_job_openings",headerName:"Active Jobs", width: 200 },
          { field: "total_job_posts",headerName:"Total Jobs", width: 200 },
          { field: "closed_job_posts",headerName:"Closed Jobs", width: 200 },
          // { field: "onboarded_staff", width: 200 },
          // { field: "no_of_staff", width: 200 },
          // { field: "acm_email", headerName: "Account Manager", width: 300 },
          {
            field: "acm_email",
            width: 400,
            headerName:"Account Manager",
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center",gap:"8px" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                       {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },          {
            field: "moderator_email",
            width: 400,
            headerName:"Moderators",
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center",gap:"8px" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                       {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },          {
            field: "te_email",
            width: 400,
            headerName:"Evaluators",
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center",gap:"8px" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                       {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },{
            field: "sp_email",
            width: 400,
            headerName:"Screening Partners",
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center",gap:"8px" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                       {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },          {
            field: "rc_email",
            width: 400,
            headerName:"Recruitment Partners",
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center",gap:"8px" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                       {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },          {
            field: "bgv_email",
            width: 400,
            headerName:"Background Partners",
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center",gap:"8px" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                       {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },
        ]}
        rows={screeningPartners}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButton,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          baseButton: { style: { width: "100px" } },
          panel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          filterPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          toolbar: { style: {} },
          preferencesPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
        }}
      />
      {openSkillModal && (
        <SkillViewModal
          rowdata={selectedRowData}
          closeModalFunc={handleCloseModal}
          openModal={openSkillModal}
          headerName={modalName}
        />
      )}
    </div>
  );
}
