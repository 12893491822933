import { Stack } from "@mui/material";
import {
  BlackTypography,
  PrimaryButton,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import JobPostingStepper from "components/Posting/stepper";
import BasicDetails from "components/Posting/steps/BasicDetails";
import HireInDay from "components/Posting/steps/HireInDay";
import JobDescriptionDetails from "components/Posting/steps/JobDescription";
import {
  getAllEducationalSpecialization,
  getEducationalSpecializationWithQualification,
  updateEducationalSpecialization,
} from "helpers/services/jobAttributes/educationalQualifications";
import { CreateJobPost } from "helpers/services/jobs/jobs";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import SocketConnection from "hooks/sockets";
import React, {useEffect} from 'react';
import { get_chat_active_user_list } from "helpers/services/sockets";

export default function JobPosting() {

  const { lastMessage, sendMessage: sendSocketMessage,connectionStatus } = SocketConnection();
  const userData = JSON.parse(localStorage.getItem("authUser"));
  const userId = userData?.id


  const [activeStep, setActiveStep] = useState(0);
  function findstep(active) {
    switch (active) {
      case 0:
        return <BasicDetails />;
      case 1:
        return <JobDescriptionDetails />;
      case 2:
        return <HireInDay />;
    }
  }
  const history = useHistory();

/*socket implementation start here */


/*socket implementation start here */


  const onSubmit = async data => {
    
    // console.log((data.min_sal.replace(/,/g, "")),"salary")
    if (activeStep === 2) {
      if (Number(data.refer_percentage) < 8.3) {
        toast.error("Refer percentage should be greater than 8.3%");
      }
      data.commission_rate_type = "Percentage";
      data.job_level_id = data.job_level_id.value;
      data.commission_rate = data.refer_percentage;
      data.functional_areas = data.functional_area;

      // data.min_sal = Number(data.min_sal);
      // data.max_sal = Number(data.max_sal);
      data.min_sal = data.min_sal.replace(/,/g, "")
      data.max_sal = data.max_sal.replace(/,/g, "")
      data.screening_questions = data.question?.map(item => item?.value);
      data.application_deadline = new Date(data.application_deadline);
      data.hours_per_week = "less_than_30";
      // data.project_duration = "less_than_month";
      data.project_duration = data.project_duration?data.project_duration:"0";
      data.job_roles = [data.job_role?.value];
      data.location = data?.location? data.location:"Remote"
      data.payout = data.payout
      data.max_exp = data.min_exp === "10+" ? "0" : data.max_exp
      if (data?.location === "") {
        delete data.location;
      }
      delete data.job_description;
      // delete data.payout;
      delete data.refer_amount;
      delete data.refer_percentage;
      delete data.question;
      delete data.job_role;
      delete data.functional_area;
      delete data.job_requirement;
      // const educational_qualifications = data?.educational_qualifications?.map(
      //   async item => {
      //     const [specialization_data] =
      //       await getEducationalSpecializationWithQualification(item?.value);

      //     return {
      //       education_qualification_id:
      //         specialization_data?.length > 0
      //           ? specialization_data[0]?.educational_qualification_id
      //           : item?.value,
      //       education_qualification_spec_id:
      //         specialization_data?.length > 0 ? specialization_data[0]?.id : "",
      //     };
      //   }
      // );
      const educational_qualifications = data?.educational_qualifications?.map(
        item => {
          const [education_qualification_id, education_qualification_spec_id] =
            item;
          return {
            education_qualification_id,
            education_qualification_spec_id,
          };
        }
      );
      // const ed_full = await Promise.all(educational_qualifications);
      const ed_full = (educational_qualifications);
      const functional_areas = [data?.functional_areas?.value];
      const key_skills = data?.key_skills?.map(item => item?.value);
      // console.log({
      //   ...data,
      //   functional_areas,
      //   educational_qualifications: ed_full,
      //   key_skills,
      // },"v");
      const { status } = await CreateJobPost({
        ...data,
        functional_areas,
        educational_qualifications: ed_full,
        key_skills,
      });
      if (status === 201) {

        await sendSocketMessage(
          JSON.stringify({
            type: "To_recipient",
            message: {
              data: "job posted",
            },
            reciever_connectionId: `${userId}`,
          })      
        )       

        toast.success("The job is posted successfully")
        history.push("/joblist/pending");
      }
      return;
    }
    setActiveStep(activeStep + 1);
  };

  // const schema = yup
  //   .object({
  //     max_sal: yup
  //       .number()
  //       .required()
  //       .min(Number(methods.watch("min_sal"))),
  //   })
  //   .required();
  const methods = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      time_zone: [],
      functional_area: [],
      currency: "INR",
      key_skills: [],
      educational_qualifications: [],
      refer_percentage: "8.3",
      question: [" "],
      max_exp_months: "0",
      min_exp_months: "0",      
    },
  });
  function handleBack() {
    if (activeStep > 0) setActiveStep(activeStep - 1);
    else history.goBack();
  }
  return (
    <Stack spacing={2}>
      <JobPostingStepper activeStep={activeStep} />
      <FormProvider {...methods}>
        <form
          style={{ padding: "0px 50px", overflow: "scroll", height: "72vh" }}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {findstep(activeStep)}
          <Stack
            sx={{
              // position: "absolute",
              marginBlock: "16px",
              background: "white",
            }}
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            <SecondaryFilledButton
              sx={{ width: "100px", color: "#D60D0D" }}
              type="button"
              onClick={handleBack}
            >
              {activeStep === 0 ? "cancel" : "back"}
            </SecondaryFilledButton>

            <PrimaryButton sx={{ width: "200px" }} type="submit">
              {activeStep === 2 ? "Publish this Job" : "Proceed to next step"}
            </PrimaryButton>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
}
