import {
  Avatar,
  FormControl,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Select,
  Drawer,
  Stack,
  Typography,
  Chip,
  Box,
} from "@mui/material";
import { blue, indigo } from "@mui/material/colors";
import { roleRestrictionsForHash } from "common/constants/roleRestrictions";
import {
  ErrorSolidButton,
  PrimaryOutlinedButton,
  PrimaryPopUp,
  StyledListItemButton,
  PrimaryAvatar,
  StyledProfilePopText,
  BlackTypography,
  SuccessSolidButton,
  GreyTypography,
  PrimaryChip,
  SmallPrimaryAvatar,
  ExtraSmallTypography,
} from "assets/Mui/globalTheme";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useEffect, useState } from "react";
import UserJobPostPopUp from "./userJobPopUp";
import { startScreening } from "helpers/services/screeningPartner/screeningPartner";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { startScreeningUserJob } from "store/screening/actions";
import SearchParamHook from "hooks/searchParams";
import { useDispatch, useSelector } from "react-redux";
import Timer from "utils/timer/Timer";
import { Link } from "react-router-dom";
import RejectConfirmation from "./rejectConfirmation";
import {
  getAllStages,
  updateFromPipeline,
} from "helpers/services/kanban/getKanbanDetails";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@mui/styles";
import { getSingleJobDetails } from "helpers/services/jobs/jobs";
import moment from "moment";
import { imageURL } from "common/constants/commonURLS";
import { toast } from "react-hot-toast";
import { profileRatingStatus } from "helpers/services/screeningPartner/screeningPartner";
import MaskedInput from "string-mask";
import {
  pipelineStages,
  roleRestrictions,
  stageACM,
  stageBG,
  stageCompany,
  stageModerator,
  stageSP,
  stageTE,
  stageZA,
} from "common/constants/roleRestrictions";
import { formatNumberWithCommas } from "helpers/functions/formatAmount";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  ACM,
  BVC,
  BVS,
  CA,
  CS,
  MODERATOR,
  TE,
  ZA,
  SP,
} from "common/constants/userModules";
import ProfileRatings from "./profileRatingModal";
import MoveCandidateModel from "./MoveCandidateModel";
import { AirlineSeatReclineNormal } from "@mui/icons-material";

const useStyles = makeStyles({
  anchor: {
    "& a": {
      color: "#024430 !important",
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 0.8fr",
    gap: 8,
    padding: 8,
    "& >div:first-of-type": {
      border: "1px solid #ddd",
      borderRadius: 6,
      "& >div": {
        margin: 8,
        paddingRight: 8,
        borderRadius: 6,
        "& h2": {
          // display:'none'
        },
        "& p": {
          marginTop: 8,
          // display:'none',
        },
      },
    },
    "& >div:last-of-type": {
      "& >div": {
        border: "1px solid #ddd",
        borderRadius: 6,
        marginBottom: 12,
      },
    },
  },
  gridTable: {
    marginBlock: "12px",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    paddingInline: "10px",
    gap: "6px",
    // alignItems:'center'
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  style: {
    maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
    width: 250,
    background: "white",
    marginTop: "10px",
    fontSize: "14px",
    padding: "10px 0px",
    borderRadius: "10px",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
  },
};

export default function UserAvatar() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [reject, setReject] = React.useState(false);
  const [viewJobInfo, setViewJobInfo] = useState(false);
  const [jobPostInfo, setJobPostInfo] = useState(null);
  const [moveInfo, setMoveInfo] = React.useState([]);
  const [currentStage, setCurrentStage] = React.useState("");
  const [currentStageId,setCurrentStageId] = React.useState("");
  const screening = useSelector(state => state.Screening);
  const role = useSelector(state => state.Login.role);
  const { candidate } = useSelector(state => state.Candidates);
  const searchParams = SearchParamHook();
  const history = useHistory();
  const [viewTimer, setViewTimer] = useState(false);
  const userId = searchParams.get("user_id");
  const jobPostId = searchParams.get("job_id");
  const userJobPostId = searchParams.get("user_job_post_id");
  const order = searchParams.get("stage_order")
  const [viewPR, setViewPR] = useState(false);
  const [screeningStatus, setScreeningStatus] = useState(true);
  const [moveCandidate,setMoveCandidate] = useState(false)
  const [reCall,setReCall] = useState(false)
  /*Stage Move button visible check testing start here */
  // const pendingCountState = useSelector(state => state?.pendingCountReducer)
  // let moveStageCheck = pendingCountState?.stageMove
  /*Stage Move button visible check testing start here */

  let extract;
  if (Object.keys(roleRestrictions).includes(role)) {
    extract = roleRestrictions[role];
  }
  function handleDrawer() {
    setViewJobInfo(false);
  }
  function handleReject() {
    setReject(!reject);
  }
  function handleConfirmReject() {
    setReject(!reject);
    const rejectStage = moveInfo?.find(item =>
      item?.stage_name?.includes("Reject")
    );
    const oldStageId = moveInfo?.find(
      item => item?.stage_name === searchParams?.get("stage_name")
    );
    updateFromPipeline(userJobPostId, rejectStage?.id, oldStageId?.id);
    searchParams.set("stage_name", rejectStage?.stage_name);
    history.replace({ search: searchParams.toString() });
  }
  useEffect(() => {
    (async function () {
      const res = await getSingleJobDetails(jobPostId);
      const { data } = res;
      setJobPostInfo(data);
    })();
  }, []);
  useEffect(() => {
    return () => {
      if (screening?.id === userJobPostId) {
        let confirmText = confirm("are you sure to leave this page?");
        if (confirmText) {
          stopScreening();
          dispatch(
            startScreeningUserJob({
              id: "",
              type: false,
            })
          );
        } else {
          history.go(1);
          return;
        }
      }
    };
  }, []);

  // useEffect(()=>{
  //   const userJobPostId = searchParams.get("job_id");
  //   const stage = searchParams?.get("stage_order");

  //   if (userJobPostId) fetchAllStages(userJobPostId, +stage);
  // },[])

  useEffect(() => {
    const userJobPostId = searchParams.get("job_id");
    const stage = searchParams?.get("stage_order");

    if (userJobPostId) fetchAllStages(userJobPostId, +stage);

    if (stage) setCurrentStage(stage);
  }, [searchParams]);

 

  async function fetchAllStages(userJobPostId, stage) {
    const { data } = await getAllStages(userJobPostId);
    const stages = [...data];
    
    let currentStaId = stages?.filter((each)=>each.order == stage)
  
    if(currentStaId.length > 0){
      setCurrentStageId(currentStaId[0].id)
    }

  

    // const index = stages.findIndex(({ order }) => order === +stage);
    // stages.splice(index, 1);
    let AuthStages;
    if (role === ZA) {
      AuthStages = stageZA(stage);
    }
    if ([CS, CA].includes(role)) {
      AuthStages = stageCompany(stage);
    }
    if (role === SP) {
      AuthStages = stageSP(stage);
    }
    if (role === MODERATOR) {
      AuthStages = stageModerator(stage);
    }
    if (role === TE) {
      AuthStages = stageTE(stage);
    }
    if (role === ACM) {
      AuthStages = stageACM(stage);
    }
    if ([BVC, BVS].includes(role)) {
      AuthStages = stageBG(stage);
    }
 
    // console.log(stages,extract,index,typeof stage,"v");
    const authList = stages.filter(stage => AuthStages.includes(stage.order));
    // console.log(stages,authList,"v");


    setMoveInfo(authList);
    // setMoveInfo(data);
  }

  useEffect(() => {
    if (screening?.id === userJobPostId) {
      window.onbeforeunload = async event => {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      };
      window.onunload = async function () {
        const { status } = await stopScreening();
        dispatch(
          startScreeningUserJob({
            id: "",
            type: false,
          })
        );
        return;
      };
    }
  }, [screening?.id]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const [interviewAnchor, setInterviewAnchor] = React.useState(null);
  const open = Boolean(interviewAnchor);
  const handleInterviewClick = event => {
    setInterviewAnchor(event.currentTarget);
  };
  const handleInterviewClose = () => {
    setInterviewAnchor(null);
  };

  /*Profile rating status check here */
  const checkProfileRatingStatus = async()=>{
     const ratingStatus = await profileRatingStatus(userJobPostId)
     console.log(ratingStatus.data.result,"rating...")
     if(ratingStatus?.data?.result.length <= 0){
      setViewPR(true)

     } else {
      toast.error("Profile rating already done")
     }
  }
/*Profile rating status check here */

  // async function stopScreening() {
  //   setScreeningStatus(!screeningStatus);
  //   setViewTimer(false);
  //   if (
  //     userJobPostId == null ||
  //     userJobPostId == undefined ||
  //     userJobPostId === ""
  //   )
  //     return;
  //   if (screening?.id !== userJobPostId) return;
  //   const result = await startScreening(userJobPostId, false, "stop");
  //   dispatch(
  //     startScreeningUserJob({
  //       id: "",
  //       type: false,
  //     })
  //   );
  //   localStorage.removeItem("second");
  //   localStorage.removeItem("minute");
  // }

 

  /* Start screening code start here */

  const EvalStartTime =
  screening?.partnerDetails?.evaluation_start_time ||
  candidate?.user_job_post_details?.evaluation_start_time;
const currentTime = new Date().getTime();
const diffInMilliseconds = new Date(EvalStartTime).getTime() - currentTime;


  async function startScreeningJob() {
    setScreeningStatus(!screeningStatus);
    setViewTimer(!screeningStatus);
    if (
      userJobPostId == null ||
      userJobPostId == undefined ||
      userJobPostId === ""
    )
      return;

    let result;

    if ([TE, MODERATOR, SP].includes(role)) {
      result = await startScreening(
        userJobPostId,
        "start",
        role === TE ? "evaluator" : role
      );
    }

    if (result?.data) {
      if (screening?.id === userJobPostId) {
        dispatch(
          startScreeningUserJob({
            id: "",
            type: false,
            timer: diffInMilliseconds,
            // partnerDetails:result?.data?.result
          })
        );
        localStorage.removeItem("second");
        localStorage.removeItem("minute");
        return;
      } else {
        dispatch(
          startScreeningUserJob({
            id: userJobPostId,
            type: !screening?.type,
            partnerDetails: result?.data?.result,
          })
        );
      }
    }

    // if (true) {
    //   if (screening?.id === userJobPostId) {
    //     dispatch(
    //       startScreeningUserJob({
    //         id: "",
    //         type: false,
    //         partnerDetails:result?.data?.result
    //       })
    //     );
    //     localStorage.removeItem("second");
    //     localStorage.removeItem("minute");
    //     return;
    //   }
    //   dispatch(
    //     startScreeningUserJob({
    //       id: userJobPostId,
    //       type: !screening?.type,
    //       partnerDetails:result?.data?.result
    //     })
    //   );
    // }
  }

  function handleTimer() {
    setViewTimer(false);
  }

  /* Start screening code End here */

  // const viewTimer = [TE,SP,MODERATOR].includes(role)
  // const viewTimer  = Boolean(timerStats.endTime) || timerStats.canViewTime

  /* Email hashing */
  const mask = new MaskedInput('AA*****AA', { A: /[A-Za-z]/ });
  const hashedNames = (data)=>{
    const stageHashCheck = roleRestrictionsForHash[role]?.includes(parseInt(order))
  
    if(stageHashCheck){
      return data;
    }else {
   
      const hashedOne = mask.apply(data)
      return hashedOne;
    }
  }
  /* Email hashing */

  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack direction={"row"} spacing={2} alignItems="center">
        <PrimaryAvatar
          alt="User Logo"
          src={`${imageURL}${candidate?.profile_image}`}
        >
          {candidate?.first_name?.charAt("0")}
        </PrimaryAvatar>
        <Stack spacing={0.5}>
          <BlackTypography variant="h4" component="h5">
            {
              candidate?.user_job_post_details?.job_post_in_user_job_post
                ?.job_title
            }
          </BlackTypography>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 32 32"
              id="building"
            >
              <path d="M20 4h2v2h-2zm4 0h2v2h-2zm-4 4h2v2h-2zm4 0h2v2h-2zm-4 4h2v2h-2zm4 0h2v2h-2zm-4 4h2v2h-2zm4 0h2v2h-2zm-4 4h2v2h-2zm4 0h2v2h-2zm4-20H18a2 2 0 0 0-2 2v6H6a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM12 30h-2v-4h2v4zm2 0v-6H8v6H6V10h10v20h-2zm10 0h-2v-4h2v4zm4 0h-2v-6h-6v6h-2V2h10v28zM8 12h2v2H8zm4 0h2v2h-2zm-4 4h2v2H8zm4 0h2v2h-2zm-4 4h2v2H8zm4 0h2v2h-2z"></path>
            </svg>
            <ExtraSmallTypography variant="h6">
              {jobPostInfo?.company_details?.company_name}
            </ExtraSmallTypography>
          </Stack>
          <Chip
            size="small"
            sx={{
              alignSelf: "flex-start",
              textTransform: "capitalize",
              bgcolor: "#cde2e7",
              color: "#024430",
            }}
            label={`${candidate?.first_name} ${candidate?.last_name}`}
          />
             <ExtraSmallTypography variant="h6">
              {candidate?.email?hashedNames(candidate?.email):"N/A"}
            </ExtraSmallTypography>
        </Stack>

        {/* {screening?.type && screening?.id === userJobPostId ? <Timer /> : ""} */}
        {/* {viewTimer ? <Timer handleTimer={()=>setViewTimer(false)} /> : ""} */}
        

        {/* {!screeningStatus ? (
          <Timer
            handleTimer={() => setViewTimer(false)}
            startScreeningJob={() => startScreeningJob()}
          />
        ) : (
          ""
        )} */}

      </Stack>
      <Stack direction="row" spacing={2} alignItems={"center"}>
        {/* <PrimaryOutlinedButton
          component={Link}
          to="/joblist/jobs/schedule-interview"
          sx={{ width: "200px" }}
          startIcon={<FeatherIcon icon="calendar" size="14" />}
        >
          Schedule Interview
        </PrimaryOutlinedButton> */}

        <Tooltip
          title={`${
            [TE, MODERATOR, SP].includes(role) &&
            screening?.type &&
            screening?.id === userJobPostId
              ? "Stop Screening"
              : ![TE, MODERATOR, SP].includes(role)
              ? "View Screening"
              : "Start Screening"
          }`}
        >
          <IconButton
            onClick={() => {
              handleClose();
              startScreeningJob();
              handleTimer();
            }}
          >
            <FeatherIcon
              icon={`${
                [TE, MODERATOR, SP].includes(role) &&
                screening?.type &&
                screening?.id === userJobPostId
                  ? "stop-circle"
                  : "monitor"
              }`}
            />
          </IconButton>
        </Tooltip>

        {/* {screening?.type && screening?.id === userJobPostId ? <Tooltip title="Start Screening">
          <IconButton  onClick={() => {
            handleClose();
            startScreeningJob();
            handleTimer();
          }}>
            <FeatherIcon icon="monitor" />
          </IconButton>
        </Tooltip>: <Tooltip title="Stop Screening">
          <IconButton  onClick={() => {
            handleClose();
            stopScreening();
            handleTimer();
          }}>
            <FeatherIcon icon="stop-circle"/>
          </IconButton>
        </Tooltip>} */}

        {[TE, MODERATOR, SP].includes(role) && screeningStatus ? (
          <PrimaryOutlinedButton
            // onClick={() => setViewPR(true)}
            onClick={()=>checkProfileRatingStatus()}
            sx={{ width: "200px" }}
          >
            Profile Rating
          </PrimaryOutlinedButton>
        ) : null}
        {/* <ErrorSolidButton
          sx={{ width: "150px" }}
          onClick={handleReject}
          startIcon={<FeatherIcon icon="thumbs-down" size="14" />}
        >
          {currentStage === 16 ? "Rejected" : "Reject"}
        </ErrorSolidButton> */}

        {[TE, MODERATOR, SP,CS,CA].includes(role) && screeningStatus ? (
          <SuccessSolidButton
            sx={{ width: "180px" }}
            onClick={handleInterviewClick}
            startIcon={<FeatherIcon icon="users" size="14" />}
            endIcon={<FeatherIcon icon="chevron-down" size="14" />}
          >
            Move Candidate
          </SuccessSolidButton>
        ) : null}

        {/* {[TE, MODERATOR, SP].includes(role) && screeningStatus ? (
          <Tooltip title={`${currentStage === 16 ? "Rejected" : "Reject"}`}>
            <IconButton onClick={handleReject}>
              <FeatherIcon icon="x-square" />
            </IconButton>
          </Tooltip>
        ) : null} */}

        {/* {moveStageCheck? <SuccessSolidButton
          sx={{ width: "180px" }}
          onClick={handleInterviewClick}
          startIcon={<FeatherIcon icon="users" size="14" />}
          endIcon={<FeatherIcon icon="chevron-down" size="14" />}
        >
          Move Candidate
        </SuccessSolidButton>:""} */}

        <PrimaryPopUp
          id={open ? "move-candidate-popper" : undefined}
          open={open}
          PaperProps={MenuProps}
          anchorEl={interviewAnchor}
          onClose={handleInterviewClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {
            <StyledListItemButton>
              <StyledProfilePopText>
                <Stack alignItems={"center"} direction="row" spacing={2}>
                  <Typography
                    sx={{ color: "green" }}
                    variant="h6"
                    component="h6"
                  >
                    <FeatherIcon icon="check" size="14" />
                  </Typography>
                  <Typography
                    sx={{ color: "green", fontSize: "15px" }}
                    variant="h6"
                    component="h6"
                  >
                    {pipelineStages[currentStage]}
                  </Typography>
                </Stack>
              </StyledProfilePopText>
            </StyledListItemButton>
          }
          {moveInfo
            ?.filter(act => act.order !== currentStage)
            .map(item => (
              <StyledListItemButton
                key={item?.order}
                onClick={async () => {
                  const oldStageId = moveInfo?.find(
                    item =>
                      item?.order === searchParams?.get("stage_order")
                  );
                  const { status, data } = await updateFromPipeline(
                    userJobPostId,
                    item?.id,
                    currentStageId,
                    // oldStageId?.id
                  );
                  if ([200, 201].includes(status)) {
                    toast.success(data.message);
                    searchParams.set("stage_order", item?.order);
                    history.replace({ search: searchParams.toString() });
                  } else {
                    toast.error("oops something went wrong!!");
                  }
                }}
              >
                <StyledProfilePopText>
                  <BlackTypography variant="h6" component="h6">
                    {item?.stage_name}
                  </BlackTypography>
                </StyledProfilePopText>
              </StyledListItemButton>
            ))}
        </PrimaryPopUp>
        {/* <IconButton onClick={() => setViewJobInfo(true)}>
          <FeatherIcon style={{ fill: "#fff" }} icon="info" size="20" />
        </IconButton> */}
        <IconButton onClick={handleClick}>
          <FeatherIcon style={{ fill: "#fff" }} icon="info" size="20" />
        </IconButton>
      </Stack>
      <RejectConfirmation
        reject={reject}
        handleClose={handleReject}
        handleReject={handleConfirmReject}
      />
      <UserJobPostPopUp
        openDrawer={() => setViewJobInfo(true)}
        handleClose={handleClose}
        handleTimer={() => setViewTimer(true)}
        anchorEl={anchorEl}
      />
      <Drawer open={viewJobInfo} anchor="right" onClose={handleDrawer}>
        <Box sx={{ width: "45vw", background: "#fff", height: "100%" }}>
          <Stack
            className={classes.anchor}
            boxShadow={2}
            p={1.25}
            direction={"row"}
            alignItems={"center"}
            sx={{ background: "#cde2e7", color: "#024430" }}
            justifyContent={"space-between"}
          >
            <Link to={`/joblist/jobs/${jobPostId}`}>
              <Typography style={{ color: "#024430 !important" }}>
                {
                  candidate?.user_job_post_details?.job_post_in_user_job_post
                    ?.job_title
                }
              </Typography>
            </Link>
            <IconButton onClick={handleDrawer}>
              <FeatherIcon fill={"#fff"} icon="x" size={18} />
            </IconButton>
          </Stack>
          <div className={classes.gridContainer}>
            <div>
              <div style={{ height: "350px", overflowY: "scroll" }}>
                <Typography
                  gutterBottom
                  boxShadow={2}
                  sx={{
                    background: "#cde2e7",
                    color: "#024430",
                    padding: "8px",
                  }}
                >
                  Job Description
                </Typography>
                <div
                  dangerouslySetInnerHTML={{ __html: jobPostInfo?.job_desc }}
                ></div>
              </div>
              <hr style={{ marginInline: "8px" }} />
              <div
                style={{
                  height: "200px",
                  overflowY: jobPostInfo?.requirements && "scroll",
                }}
              >
                <Typography
                  gutterBottom
                  boxShadow={2}
                  sx={{
                    background: "#cde2e7",
                    color: "#024430",
                    padding: "8px",
                  }}
                >
                  Job Requirements
                </Typography>
                {jobPostInfo?.requirements && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: jobPostInfo?.requirements,
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div>
              <div>
                <Typography
                  gutterBottom
                  boxShadow={2}
                  sx={{
                    background: "#cde2e7",
                    color: "#024430",
                    padding: "8px",
                  }}
                >
                  Job Insights
                </Typography>
                <div className={classes.gridTable}>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Job Level
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.new_job_level?.name}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Job Type
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.job_type}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Experience
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.min_exp}-{jobPostInfo?.max_exp}yrs
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Min Salary
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {getSymbolFromCurrency(jobPostInfo?.currency) +
                      " " +
                      formatNumberWithCommas(jobPostInfo?.min_sal)}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Max Salary
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {getSymbolFromCurrency(jobPostInfo?.currency) +
                      " " +
                      formatNumberWithCommas(jobPostInfo?.max_sal)}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Vacancies
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.no_of_vacancies}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Work Mode
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.work_mode}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Location
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.location}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Applied
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.applicants_count}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Application Deadline
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {moment(jobPostInfo?.application_deadline).format("ll")}
                  </GreyTypography>
                </div>
              </div>
              <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
                <Typography
                  gutterBottom
                  boxShadow={2}
                  sx={{
                    background: "#cde2e7",
                    color: "#024430",
                    padding: "8px",
                  }}
                >
                  Skills
                </Typography>
                <Stack
                  direction={"row"}
                  flexWrap={"wrap"}
                  p={2}
                  gap={2}
                  alignItems={"flex-start"}
                >
                  {jobPostInfo?.key_skills_details?.map(skill => (
                    <PrimaryChip
                      size="small"
                      key={skill.id}
                      label={skill.name}
                    />
                  ))}
                </Stack>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
      {viewPR ? (
        <ProfileRatings toggleModel={() => setViewPR(false)} open={viewPR} setMoveCandidate={setMoveCandidate} moveCandidate={moveCandidate} userJobPostId={userJobPostId} role={role}/>
      ) : null}

    {moveCandidate ? (
        <MoveCandidateModel toggleModel={() => setMoveCandidate(false)} open={moveCandidate} stagesInfo={moveInfo}   userJobPostId={userJobPostId} currentStage={currentStage} currentStageId={currentStageId}/>
      ) : null}

    </Stack>
  );
}
