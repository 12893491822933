import * as React from "react";
import {
  PrimaryPopUp,
  GrayDivider,
  StyledListItemButton,
  StyledProfilePopText,
  BlackTypography,
} from "assets/Mui/globalTheme";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClipBoardData from "helpers/dashboard/clipboard";
import SearchParamHook from "hooks/searchParams";
import { startScreening } from "helpers/services/screeningPartner/screeningPartner";
import { TE, SP, MODERATOR } from "common/constants/userModules";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { startScreeningUserJob } from "store/screening/actions";

function timerInfo(role,candidate) {
  let canViewTime;
  let endTime;
  switch (role) {
    case SP:
      canViewTime = candidate?.is_screened
      endTime = candidate?.screening_end_time
      break;
    case TE:
      canViewTime = candidate?.is_evaluated
      endTime = candidate?.evaluation_end_time
      break;
    default:
      canViewTime = candidate?.is_moderated
      endTime = candidate?.moderator_end_time
  }
  return {
    endTime,
    canViewTime
  }
}

export default function UserJobPostPopUp({openDrawer, handleClose, anchorEl,handleTimer }) {
  const user = useSelector(state => state?.Login);
  const {candidate} = useSelector(state=>state.Candidates)
  const role = user?.role
  const open = Boolean(anchorEl);
  const id = open ? "profile-popover" : undefined;
  const [logout, setLogout] = React.useState(false);
  function handleLogout() {
    setLogout(!logout);
  }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    style: {
      maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
      width: 250,
      background: "white",
      marginTop: "10px",
      fontSize: "14px",
      padding: "10px 0px",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  };
  const timerInfoObj = timerInfo(role,candidate?.user_job_post_details)
  const screening = useSelector(state => state.Screening);
  const dispatch = useDispatch();
  const searchParams = SearchParamHook();
  const userJobPostId = searchParams.get("user_job_post_id");

  async function startScreeningJob() {
    if (
      userJobPostId == null ||
      userJobPostId == undefined ||
      userJobPostId === ""
    )
      return;
    const result = await startScreening(
      userJobPostId,
      'start',
      role === TE ? 'evaluator' : role.toLowerCase()
    );
    // 'start',
    // role.toLowerCase()
    // ErrorHandlingToaster(result);
    // console.log(screening,'veda');
    if (true) {
      if (screening?.id === userJobPostId) {
        dispatch(
          startScreeningUserJob({
            id: "",
            type: false,
          })
        );
        localStorage.removeItem("second");
        localStorage.removeItem("minute");
        return;
      }
      dispatch(
        startScreeningUserJob({
          id: userJobPostId,
          type: !screening?.type,
        })
      );
    }
  }
  // console.log(screening,timerInfoObj,candidate,'veda');

  const viewScreen = [TE,SP,MODERATOR].includes(role) 
  const disableTimer  = Boolean(timerInfoObj.endTime) || timerInfoObj.canViewTime


  /* Time Functionality code start @ seshu */

  /* Time Functionality code end @ seshu */

  return (
    <>
      <PrimaryPopUp
        id={id}
        open={open}
        PaperProps={MenuProps}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {/* {viewScreen && <StyledListItemButton
          onClick={() => {
            handleClose();
            startScreeningJob();
            handleTimer();
          }}
          // style={{pointerEvents: disableTimer ? 'none' : 'auto'}}
        >
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              {screening?.type && screening?.id === userJobPostId
                ? "Stop "
                : "Start "}
              Screening
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton>} */}


    <StyledListItemButton onClick={()=>{handleClose();openDrawer()}}
     component={Link}
     to="/joblist/jobs/schedule-interview"
    >
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
           Schedule Interview
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton>

        <StyledListItemButton onClick={()=>{handleClose();openDrawer()}}>
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              View Job Info
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton>
        <StyledListItemButton
          onClick={() => {
            handleClose();
            ClipBoardData(window.location.href, "Candidate profile");
          }}
        >
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              Share candidate profile
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton>
        {/* <GrayDivider /> */}
      </PrimaryPopUp>
    </>
  );
}
