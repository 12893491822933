import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryOpacitedButton,
  PrimaryTypography,
  GrayDivider,
  BlackTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import InputButtonField from "./inputButtonField";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import SearchParamHook from "hooks/searchParams";

export default function WorkAuthInformation({ candidateDetails,fetchUserJobPostDetails,readOnly }) {
  const [candidate, setCandidate] = useState({});
  const [visaStatus, setVisaStatus] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setCandidate(candidateDetails);
  }, [candidateDetails]);

  // useEffect(()=>{
  //   const userId = searchParams.get("user_id");
  //   const jobPostId = searchParams.get("job_id");
  //   if (userId && jobPostId) {
  //     fetchUserJobPostDetails(userId, jobPostId);
  //   }
  // },[visaStatus])

 const visaAvail = candidate?.visa_details?.map((each)=>each?.visa)
 const visaFrom = candidate?.visa_details?.map((each)=>each?.from)
 const visaTo = candidate?.visa_details?.map((each)=>each?.to)
 const visaType = candidate?.visa_details?.map((each)=>each?.type)

  return (
    <Stack spacing={2} className="mt-3">
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
        <PrimaryTypography component="h4" variant="h4">
          Work Authorization
        </PrimaryTypography>
      </Stack>
      <BorderCard>
        <CardContent>
          <Stack spacing={1}>
            <InputButtonField
              text_field={"Country*"}
              input_value={candidate?.nationality?candidate?.nationality:"India"}
              type="nationality"
              update_type={"nationality"}
              readOnly={readOnly}
            />

            <InputButtonField
              text_field={"Visa status*"}
              input_value={visaAvail}
              type="visa"
              update_type={"visa"}
              setVisaStatus={setVisaStatus}
              candidateDetails={candidate}
              fetchUserJobPostDetails={fetchUserJobPostDetails}
              readOnly={readOnly}
            />

            {visaStatus == "Yes" || visaAvail == "Yes"? (
              <>
                <InputButtonField
                  text_field={"From"}
                  // input_value={candidate?.visa_details[0]?.from}
                  input_value={visaFrom}
                  type="visafrom"
                  update_type={"from"}
                  candidateDetails={candidate}
                  readOnly={readOnly}
                />
                <InputButtonField
                  text_field={"To"}
                  // input_value={candidate?.visa_details[0]?.to}
                  input_value={visaTo}
                  type="visato"
                  update_type={"to"}
                  candidateDetails={candidate}
                  readOnly={readOnly}
                />
                <InputButtonField
                  text_field={"Type"}
                  // input_value={candidate?.visa_details[0]?.file}
                  input_value={visaType}
                  type="file"
                  update_type={"file"}
                  candidateDetails={candidate}
                  readOnly={readOnly}
                />
              </>
            ) : (
              ""
            )}
          </Stack>
        </CardContent>
      </BorderCard>
    </Stack>
  );
}
