import * as React from "react";
import { ListItemButton, ListItemText,Box,Stack,IconButton } from "@mui/material"
import {
  ExtraSmallTypography,
  NotificationPopUp,
  GrayDivider,
  StyledListItemButton,
  StyledProfilePopText,
  BlackTypography,
  NotificationBox,
  NotificationHeader,
  StyledListCard
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import LogoutModel from "./logout";
import { useSelector } from "react-redux";
import { ACM, CS, MODERATOR } from "common/constants/userModules";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import Divider from "@mui/material/Divider";
import { NavigateBefore } from "@mui/icons-material";

export default function NotificationPopOver({ handleBellClose, notificationEl,viewModel,notificationsData }) {

  const user = useSelector(state => state?.Login);
  const open = Boolean(notificationEl);
  const role = JSON.parse(localStorage.getItem("authUser")).role;
  const id = open ? "profile-popover" : undefined;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    style: {
      maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
      width: 250,
      background: "white",
      marginTop: "10px",
      fontSize: "14px",
      padding: "10px 0px",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  };
  // function SettingsUrl() {
  //   switch (user?.role) {
  //     case "ZA":
  //       return "/profile-settings?tab=2";
  //     case "CA":
  //       return "/profile-settings?tab=2";
  //     default:
  //       return "/profile-settings?tab=2";
  //   }
  // }
  // const isValid = [CS,MODERATOR,ACM].includes(role)
  return (
    <>
      <NotificationPopUp
        id={id}
        open={open}
        // PaperProps={MenuProps}
        anchorEl={notificationEl}
        onClose={handleBellClose}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "center",
        // }}

        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

      >
        <NotificationHeader>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <BlackTypography varient="h5">All Notifications</BlackTypography>
          <Link to="../profile-settings?tab=2">
          <FeatherIcon icon="settings" size="16" style={{ color: '#024430' }}/>
          </Link>
        </Stack>
        </NotificationHeader>
        <NotificationBox>
     
        {notificationsData?.length > 0 ? (
        <>
        <Box sx={{marginTop:"37px",padding:"20px",}}>
          {notificationsData?.map((each, i) => (
            <>
      
              <StyledListCard>
              <ListItem key={i} sx={{padding:0}}>
                <ListItemAvatar>
                  <Avatar src={each.image} style={{border:"1px solid #EFF7FF"}}>
                  </Avatar>
                </ListItemAvatar>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <ListItemText
                    primary={`${each.role}`}
                    secondary={`${each.title}`}
                    primaryTypographyProps={{
                        style: {
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight:600,
                          letterSpacing:"-1%"
                         
                        },
                      }}
                    secondaryTypographyProps={{
                        style: {
                          fontFamily: 'Inter',
                          fontSize: '13px',
                          fontWeight:500,
                          letterSpacing:"-1%",
                          color:"rgba(0, 0, 0, 0.53)",
                          
                        },
                      }}
                  />
                  <ListItemText
                    secondary={`${each.time}`}
                    className="time_stamp"
                    secondaryTypographyProps={{
                        style: {
                          fontFamily: 'Inter',
                          fontSize: '13px',
                          fontWeight:"500",
                          letterSpacing:"-1%",
                          color:"rgba(0, 0, 0, 0.53)",
                          opacity:"60%",
                          display:"flex",
                          justifyContent:"flex-end"
                        },
                      }}
                  />
                </div>
              </ListItem>
              <p className="notification_msg pb-3" style={{fontSize:"13px"}}>
              &#x2022;{each.message}
                <span
                  style={{
                    background:
                      each.key === "Submitted"
                        ? "#1E874B"
                        : each.key === "Rejected"
                        ? "#f59c90"
                        : "#EFF7FF",
                    color:
                      each.key === "Submitted"
                        ? "#ffffff"
                        : each.key === "Rejected"
                        ? "#000000"
                        : "",
                    borderRadius: "4px",
                    padding: "2px",
                    paddingLeft: "5px",
                    marginLeft: "6px",
                    fontSize:"12px"
                  }}
                  className="notification_msg"
                >
                  {each.key}
                </span>
              </p>

              {/* <Divider
                sx={{
                  borderWidth: "50%",
                  borderBottom: "0.2px solid #081753",
                  background: "#081753",
                  borderColor: "#081753",
                }}
              /> */}
            </StyledListCard>
            </>
          ))}
          </Box>
        </>
      ) : (
        ""
      )}
        </NotificationBox>
      </NotificationPopUp>

    </>
  );
}
