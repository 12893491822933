import * as React from "react";
import { ListItemButton, ListItemText } from "@mui/material";
import {
  ExtraSmallTypography,
  PrimaryPopUp,
  GrayDivider,
  StyledListItemButton,
  StyledProfilePopText,
  BlackTypography,
} from "assets/Mui/globalTheme";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ClipBoardData from "helpers/dashboard/clipboard";
import { CA, ZA,ACCOUNT_MANAGER,CS } from "common/constants/userModules";
const JobCardPopUp = React.memo(function JobCard({
  handleClose,
  anchorEl,
  id: jobId,
}) {
  const user = useSelector(state => state?.Login);
  
  const open = Boolean(anchorEl);
  const id = open ? "profile-popover" : undefined;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    style: {
      maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
      width: 250,
      background: "white",
      marginTop: "10px",
      fontSize: "14px",
      padding: "10px 0px",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  };

  const path = window.location.pathname
  return (
    <>
      <PrimaryPopUp
        id={id}
        open={open}
        PaperProps={MenuProps}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <StyledListItemButton
          onClick={() =>
            ClipBoardData(`app.shifyt.com/joblist/jobs/${jobId}/`, "Job link")
          }
        >
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              Share
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton>
        {(user?.role === CA || user?.role === CS || user?.role === ACCOUNT_MANAGER || user?.role === ZA) && path !== "/joblist/approved" ? (
          <StyledListItemButton component={Link} to={`/job-edit/${jobId}?user_id=${user?.id}`}>
            <StyledProfilePopText>
              <BlackTypography variant="h6" component="h6">
                Edit
              </BlackTypography>
            </StyledProfilePopText>
          </StyledListItemButton>
        ) : (
          <></>
        )}
      </PrimaryPopUp>
    </>
  );
});
export default JobCardPopUp