import {
  Box,
  Grid,
  IconButton,
  Input,
  InputBase,
  Stack,
  Tabs,
  Chip,
  Divider
} from "@mui/material";
import {
  BlackTypography,
  BorderCard,
  GreyTypography,
  successNotification,
} from "assets/Mui/globalTheme";
import ChatImages from "components/Chat/chatPage/Images";
import RecivedMessage from "components/Chat/chatPage/recivedMessages";
import SendMessages from "components/Chat/chatPage/sendMessages";
import ChatInput from "components/Chat/chatPage/input";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import { useCallback, useState,useEffect,useRef,Fragment } from "react";
import ChatUserPopUp from "./chatUserPopUp";
import { getEvalauateMessages, sendEvaluateMessage } from "helpers/services/chats/evalautorCharts";
import { useSelector } from "react-redux";
import SearchParamHook from "hooks/searchParams";
import SocketConnection from "hooks/sockets";
import { get_chat_active_user_list,deleteChatActiveUser } from "helpers/services/sockets";
import moment from "moment";
import noChats from "../../assets/images/noChatsGreen.png";
import messageSent from '../../assets/alerts/messageSent.mp3';
import SnackbarCom from "hooks/snackBar";


const ChatDetailedPage = ({ styleProps = {},getImages }) => {
  const { lastMessage, sendMessage: sendSocketMessage,connectionStatus } = SocketConnection();
  const searchParams = SearchParamHook()
  const jobPostId = searchParams.get('job_id')
  const userJobPostId = searchParams.get('user_job_post_id')
  const receiverId = searchParams.get('user_id')
  const {candidate} = useSelector(state => state.Candidates);
  const [userPopUp, setUserPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [msgs,setMsgs]  = useState([])
  const [socketUsers,setSocketUsers] = useState([])
  const [socketRecieverId,setSocketRecieverId] = useState(null)
  // console.log(candidate,'veda');
  const chatContainerRef= useRef(null)

  const user = useSelector(state => state.Login)
  const rootUserId = user.id
 
  useEffect(()=>{
    // (async function(){
    //   const res = await get_chat_active_user_list()
    //   const a = res?.data?.filter(user=>user.userId === receiverId)
    //   setSocketUsers(res?.data?.filter(user=>user.userId === receiverId))
    // })()
    fetchActiveUsersList()
    // fetchEvaluateMessages()

  },[])

  useEffect(()=>{
    // (async()=>{
    //   const res = await get_chat_active_user_list()
    //   if(res){
    //     const socketUsers = res?.data?.filter(user=>user.userId === receiverId)
    //     setSocketUsers(socketUsers)
    //   }
    // })()
  },[connectionStatus])
  
  
  const fetchActiveUsersList = async()=>{
    const res = await get_chat_active_user_list()
  
    if(res){
      const socketUsers = res?.data?.filter(user=>user.userId === receiverId)
      setSocketUsers(socketUsers)
      setSocketRecieverId(socketUsers[0]?.connectionId)
      fetchEvaluateMessages()
    }else {
      fetchEvaluateMessages()
    }
  }

  useEffect(() => {
    const scrollToBottom = () => {
      if ( chatContainerRef.current) {
         chatContainerRef.current.scrollTop =  chatContainerRef.current.scrollHeight;
      }
    };
    scrollToBottom();
    return(()=>{
      scrollToBottom()
    })
  }, [msgs]);

  useEffect(()=>{
    if(lastMessage){
      fetchEvaluateMessages()
    }
  },[lastMessage])


  async function fetchEvaluateMessages(){
   const {data,status} =  await getEvalauateMessages({jobPostId,userJobPostId,receiverId})
   const {data:messages} = data
   const modifiedData = messages?.map(item => ({
     id: item.id,
     createdAt: item.createdAt,
     updatedAt: item.updatedAt,
     message: item.message,
     senderType: item.sender_type,
     isSeen: item.is_seen,
     isActive: item.is_active,
     chatChannel_id: item.chat_channel_id,
     receiverId: item.receiver_id,
     senderId: item.sender_id,
     jobPostId: item.job_post_id,
     userJobPostId: item.user_job_post_id,
   }));
   setMsgs(modifiedData.sort((a,b)=>new Date(a.createdAt) - new Date(b.createdAt)))
   const userSocketId =  socketUsers[0]

   if (userSocketId && status === 200 ) {
    await sendSocketMessage(
      JSON.stringify({
        type: "To_recipient",
        message: {
          data: "read message",
        },
        reciever_connectionId: userSocketId?.connectionId,
      })      
    )       
  }
  }
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    setUserPopUp(true);
  }
  const handleClose = useCallback(() => {
    setUserPopUp(false);
    setAnchorEl(null);
  }, [userPopUp]);

  /* Message send alert sound starts here @seshu */
  const playMessageSentSound = () => {
    const audio = new Audio(messageSent);
    audio.play();
  };
  /*Message send alert sound ends here @seshu */


  async function handleSendMessage(message) {
    const reqObj = {
      receiverId,
      message,
      userJobPostId,
      jobPostId,
    };
    const userSocketId =  socketUsers[0]
    if (message !== "") {
      let userSocketId
      const res = await get_chat_active_user_list()
      if(res){
        const socketUsers = res?.data?.filter(user=>user.userId === receiverId)
        userSocketId = socketUsers[0] 
      }

      const {status:statusCode} = await sendEvaluateMessage(reqObj)
      playMessageSentSound()  
      if (userSocketId && statusCode === 200 ) {
        await sendSocketMessage(
          JSON.stringify({
            type: "To_recipient",
            message: {
              data: message,
            },
            reciever_connectionId: userSocketId?.connectionId,
          })      
        )       
      }
    }
    fetchEvaluateMessages() 
    getImages() 
  }
  
  return (
    <>
      <BorderCard
        sx={{
          ...styleProps,
          height:"59vh",
          padding: "10px 20px",
          position: "relative",
          overflow:'visible',
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{
            background: "white",
            position: "absolute",
            padding: "10px 20px",
            height: "60px",
            width: "100%",
            left: "0px",
            top: "0px",
            zIndex:'2',
            borderTopLeftRadius:'10px',
            borderTopRightRadius:'10px',
            borderBottom:"1px solid #ddd",            
          }}
        >
          <Stack>
            <BlackTypography variant="h5" component="h5">
              {candidate?.first_name} {candidate?.last_name}
            </BlackTypography>
            <Stack direction="row" alignItems={"center"} spacing={1}>
              <Box
                sx={{ ...successNotification, width: "5px", height: "5px",background: (!socketUsers.length ? 'rgb(255 0 0 / 35%)':"rgba(39,174,96,0.36)") }}
              />
              <GreyTypography variant="h6" component="h6">
                {socketUsers.length ?"Online" :'Offline'}
              </GreyTypography>
            </Stack>
          </Stack>
          {/* <Stack direction="row" alignItems={"center"} spacing={1}>
            <Stack spacing={0} alignItems={"end"}>
              <BlackTypography  variant="h3" component="h3">
                {candidate?.job_post_in_user_job_post?.job_title}
              </BlackTypography>
              <GreyTypography variant="h6" component="h6">
                Syoft
              </GreyTypography>
            </Stack>
            <IconButton onClick={handleClick}>
              <FeatherIcon icon="more-vertical" size={"14"} />
            </IconButton>{" "}
          </Stack> */}
        </Stack>
{ msgs.length ?        <div
          ref={chatContainerRef}
          style={{
            // height:"calc(100% - 120px)",
            height:"calc(100% - 80px)",
            overflowY: "scroll",
            overflowX:'hidden',
            paddingBlockStart:55,
            paddingBlockEnd:20,
            paddingInline:8,
            display:'flex',
            flexDirection:'column',
            gap:'8px'
          }}
        >
          <MessageChart msgs={msgs} /> 
        </div> : 
        <div style={{height:"calc(100% - 30px)",display:'flex',justifyContent:'center',alignItems:'center'}}>
        <img style={{width:'250px',height:'250px'}} src={noChats} alt="/" />  
      </div>
        }
        <ChatInput handleSendMessage={handleSendMessage} setMsgs={setMsgs} />
      </BorderCard>
      <ChatUserPopUp handleClose={handleClose} anchorEl={anchorEl} />
    </>
  );
};
export default trackWindowScroll(ChatDetailedPage);

function MessageChart({ msgs }) {
  const evaluatorId = JSON.parse(localStorage.getItem("authUser")).id;
  return (
    <>
      {msgs?.map((msgItem, index) => {
        const { createdAt, id, message, senderId } = msgItem;
        const isExtension = /\.(jpe?g|png|jpeg|gif|pdf|docx|json)$/i.test(
          message
        );
        const isSender = evaluatorId === senderId;
        const timePinger = msgs[index-1]?.createdAt === undefined ? true : moment(msgs[index-1]?.createdAt).format("L") !== moment(msgItem.createdAt).format("L") 
        if (!isExtension) {
          if (isSender) {
            return (
              <Fragment key={index}>
                {Boolean(timePinger) && (
                  <TimeDivider time={createdAt} />
                )}
                <SendMessages key={id} message={message} time={createdAt} />
              </Fragment>
            );
          }
          return (
            <Fragment key={index}>
              {Boolean(timePinger) && (
                <TimeDivider time={createdAt} />
              )}
              <RecivedMessage key={id} message={message} time={createdAt} />
            </Fragment>
          );
        }
        return (
          <Fragment key={index}>
            {Boolean(timePinger) && <TimeDivider time={createdAt} />}
            <ChatImages key={id} sender={isSender} message={message} time={createdAt} />
          </Fragment>
        );
      })}
    </>
  );
}


function TimeDivider({time}) {
  return (
    <Divider sx={{":before,:after":{top:'0px !important'}}}>
      <Chip
        label={moment(time).calendar({
          sameDay: "[Today]",
          nextDay: "[Tomorrow]",
          nextWeek: "dddd",
          lastDay: "[Yesterday]",
          lastWeek: "DD/MM/YYYY",
          sameElse: "DD/MM/YYYY",
        })}
        size="small"
      />
    </Divider>
  );
}