import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  createFilterOptions,
} from "@mui/material";
import {
  MultipleSelectInputs,
  PrimaryAutoComplete,
  PrimaryButton,
  PrimaryNormalInputLabel,
  SecondaryFilledChip,
  autocompleteProps,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  getCommonUser,
  updateCommonUser,
  updateCommonUserWithId,
} from "helpers/services/common";
import { getAllCompaniesWithStatus } from "helpers/services/companies/company";
import { getAllFunctionalArea } from "helpers/services/jobAttributes/functionalArea";
import {
  createJobLevelForEveryone,
  getAllJobLevlsWithoutFunctionalArea,
} from "helpers/services/jobAttributes/jobLevels";
import {
  createKeySkills,
  getAllKeySkills,
  getAllKeySkillsWithStatus,
} from "helpers/services/jobAttributes/keyskills";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function SetPermissionForCommonUser() {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      job_role: [],
      functional_area: [],
      key_skills: [],
    },
  });
  // const jobLevel = useSelector(state => state.JobAttributes.jobLevels);
  const dispatch = useDispatch();
  const [functionalAreas, setFunctionalAreas] = useState([]);
  const [keySkills, setKeySkills] = useState([]);
  const [companies, setCompanies] = useState([])
  const [jobLevel,setJobLevel] = useState([])
  const keySkillFilter = createFilterOptions();
  const categoryFilter = createFilterOptions();
  const jobRoleFilter = createFilterOptions();

  useEffect(() => {
    getFunctionalAreas();
    getKeySKills();
    getAllJobLevels();
    fetchCompanies()
  }, []);
  const { id } = useParams();
  const [skillLoader, setSkillLoader] = useState(true);
  async function getKeySKills() {
    const { data, status } = await getAllKeySkillsWithStatus();
    if (status === 200) {
      setSkillLoader(false);
    }
    setKeySkills(data?.map(item => ({ name: item?.name, value: item?.id, isActive:item?.is_active })));
  }

  async function getFunctionalAreas() {
    const data = await getAllFunctionalArea();
    setFunctionalAreas(
      data?.map(item => ({ name: item.name, value: item.id, isActive:item?.is_active }))
    );
  }
  async function getAllJobLevels() {
    const data = await getAllJobLevlsWithoutFunctionalArea();
    // dispatch({
    //   type: "ADD_JOB_LEVEL",
    //   payload: data?.map(item => ({ name: item.name, value: item.id, isActive:item?.is_active })),
    // });
    console.log(data);
    setJobLevel(data?.filter(e=>e.is_active).map(item => ({ name: item.name, value: item.id,isActive:item?.is_active })));
  }
  async function fetchCompanies() {
    try {
      const { data } = await getAllCompaniesWithStatus();
            // const { data } = zee
            const companies = data.filter(item => {
              return item.company_user_details.usertype === "COMPANY";
            });
            const sorted = companies.filter(item => {
              if (item.related_to_admin === false) return item;
            });
      const alphaSort = sorted.sort((a, b) => a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase()))
      setCompanies(alphaSort.map(e => ({
        id: e.id,
        company_name: e.company_name,
        is_active:e.is_active
      })))
      
    } catch (error) {
      setCompanies([])
    }
  }
  async function onSumbit(data) {
    const results = {
      // job_role:[data?.job_role?.value],
      // functional_area: [data?.functional_area?.value],
      job_role:data?.job_role?.map(item => item.value),
      functional_area: data?.functional_area?.map(item => item.value),
      skills: data?.key_skills?.map(item => item.value),
      assigned_companies:data?.assigned_companies?.map(item=>item.id),
      user_approval:"Approve"
    };
    // console.log(results,"v");
    const {status} = await updateCommonUserWithId(id, results);
    if(status === 200){
      toast.success("User was updated successfully")
    }else {
      toast.error("Something went wrong")
    }
    initialFetch(id);
  }
  async function initialFetch(id) {
    const { data } = await getCommonUser(id);
    dispatch({ type: "ADD_COMMON_USER_DATA", payload: data });
  }
  const user_data = useSelector(state => state.CommonUser.user);
  useEffect(() => {
    console.log(user_data);
    if (user_data?.job_roles_details?.length > 0) {
      setValue(
        "job_role",
        user_data?.job_roles_details?.map(item => ({
          name: item.name,
          value: item.id,
          isActive:item?.is_active
        }))
      );
    }
    if (user_data?.functional_area_details?.length > 0) {
      setValue(
        "functional_area",
        user_data?.functional_area_details?.map(item => ({
          name: item.name,
          value: item.id,
          isActive:item.is_active
        }))
      );
    }
    if (user_data?.key_skill_details?.length > 0) {
      // functional_area_details;
      setValue(
        "key_skills",
        user_data?.key_skill_details?.map(item => ({
          name: item.name,
          value: item.id,
          isActive:item.is_active
        }))
      );
    }
    if (user_data?.assigned_company_details?.length > 0) {
      setValue("assigned_companies",user_data?.assigned_company_details?.map(e => ({
        id: e.id,
        company_name: e.company_name,
        is_active:e.is_active
      })))
    }
  }, [user_data]);

  const addedJobLevel = useSelector(state => state.Customs.jobLevel);
  useEffect(() => {
    if (addedJobLevel?.value) {
      setValue("job_role", [...watch("job_role"), addedJobLevel]);
    }
  }, [addedJobLevel]);
  console.log(watch("assigned_companies"));
  return (
    <form onSubmit={handleSubmit(onSumbit)}>
      <input
        type="text"
        style={{ display: "none" }}
        {...register("job_role", { required: {value:false,message:"should not be empty"} })}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Controller
            render={props => {
              return (
                <PrimaryAutoComplete
                  // loading={skillLoader}
                  loadingText={"Loading..."}
                  sx={{ width: "100%" }}
                  {...props}
                  // disableCloseOnSelect
                  componentsProps={autocompleteProps}
                  multiple={true}
                  options={functionalAreas}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <SecondaryFilledChip
                        deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  isOptionEqualToValue={(option,value)=>option.value===value.value}
                  renderOption={(props, option, { selected }) => (
                    option.isActive ?
                    <li {...props}>
                      <Checkbox
                        icon={<FeatherIcon icon="square" size="14" />}
                        checkedIcon={
                          <FeatherIcon icon="check-square" size="14" />
                        }
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li> : null
                  )}
                  getOptionLabel={option => option?.name || ""}
                  renderInput={params => (
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="functional-outlined"
                        error={errors.functional_area}
                      >
                        Categories
                      </PrimaryNormalInputLabel>
                      <MultipleSelectInputs
                        sx={{
                          ...(errors.functional_area && {
                            borderColor: "red",
                          }),
                        }}
                        variant="standard"
                        ref={params.InputProps.ref}
                        {...params}
                      />
                      <FormHelperText error={errors.functional_area}>{ errors?.functional_area?.message}</FormHelperText>
                    </FormControl>
                  )}
                  filterOptions={(options, params) => {
                    const filtered = categoryFilter(options, params);
                    console.log(params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      option => inputValue === option.name
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        name: `${inputValue}`,
                      });
                    }

                    return filtered;
                  }}
                  value={watch("functional_area")|| null}
                  onChange={async (_, data,reason) => {
                    // console.log(data);
                    if (reason === "clear") {
                      setValue("functional_area", [])
                      props.field.onChange([]);  
                    return  
                    }
                    const lastIndex = data?.length - 1;
                    if (data && data[lastIndex].inputValue) {
                      const { data: skill } = await createCategories({
                        name: data[lastIndex].inputValue,
                      });
                      console.log(skill);
                      await getAllFunctionalArea();
                      props.field.onChange([
                        ...getValues("functional_area"),
                        {
                          name: skill.key_skill_res.name,
                          value: skill.key_skill_res.id,
                        },
                      ]);
                    } else {
                    props.field.onChange(data);
                    }
                  }}
                />
              );
            }}
            name="functional_area"
            control={control}
            rules={{
              required: {
                value: false,
                message:"should not be empty"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <PrimaryAutoComplete
            componentsProps={autocompleteProps}
            multiple={true}
            // disabled={watch("functional_area")?.length === 0}
            error={errors.job_role}
            value={watch("job_role")|| null}
            onChange={async (e, newValue) => {
              const lastIndex = newValue?.length - 1;
              setValue("job_role",newValue)
              // if (typeof newValue[lastIndex] === "string") {
              //   // timeout to avoid instant validation of the dialog's form.
              //   setTimeout(() => {
              //     dispatch({
              //       type: "ADD_JOB_ROLE_MODAL",
              //       payload: {
              //         values: {
              //           job_role: newValue,
              //           functional_area: watch("functional_area"),
              //         },
              //       },
              //     });
              //     // toggleOpen(true);
              //   });
              // } else if (
              //   newValue[lastIndex] &&
              //   newValue[lastIndex].inputValue
              // ) {
              //   dispatch({
              //     type: "ADD_JOB_ROLE_MODAL",
              //     payload: {
              //       values: {
              //         job_role: newValue[lastIndex].inputValue,
              //         functional_area: watch("functional_area"),
              //       },
              //     },
              //   });
              // } else {
              //   setValue("job_role", newValue);
              // }
            }}
            // disableCloseOnSelect
            id="multiple-job-roles"
            options={jobLevel}
            isOptionEqualToValue={(option,value)=>option.value===value.value}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <SecondaryFilledChip
                  deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={(props, option, { selected }) => (
              option.isActive ? 
              <li {...props}>
                <Checkbox
                  icon={<FeatherIcon icon="square" size="14" />}
                  checkedIcon={<FeatherIcon icon="check-square" size="14" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li> : null
            )}
            filterOptions={(options, params) => {
              const filtered = jobRoleFilter(options, params);
              const { inputValue } = params;
              const isExisting = options.some(
                option => inputValue === option.name
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `${inputValue}`,
                });
              }

              return filtered;
            }}
            getOptionLabel={option => option?.name || ""}
            renderInput={params => {
              return (
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="job-role-outlined"
                    error={errors.job_role}
                  >
                    Job Role (select all that applies)
                  </PrimaryNormalInputLabel>
                  <MultipleSelectInputs
                    sx={{
                      ...(errors.job_role && { borderColor: "red" }),
                    }}
                    variant="standard"
                    ref={params.InputProps.ref}
                    {...params}
                  />
                  <FormHelperText error={errors.job_role}>{ errors?.job_role?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            render={props => {
              return (
                <PrimaryAutoComplete
                  loading={skillLoader}
                  sx={{ width: "100%" }}
                  {...props}
                  disableCloseOnSelect
                  componentsProps={autocompleteProps}
                  multiple={true}
                  options={keySkills}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <SecondaryFilledChip
                        deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  isOptionEqualToValue={(option,value)=>option.value===value.value}
                  renderOption={(props, option, { selected }) => (
                    option.isActive ? 
                    <li {...props}>
                      <Checkbox
                        icon={<FeatherIcon icon="square" size="14" />}
                        checkedIcon={
                          <FeatherIcon icon="check-square" size="14" />
                        }
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li> : null
                  )}
                  getOptionLabel={option => option?.name}
                  renderInput={params => (
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="key-skills-outlined"
                        error={errors.key_skills}
                      >
                        Required Skills (select all that applies)
                      </PrimaryNormalInputLabel>
                      <MultipleSelectInputs
                        sx={{
                          ...(errors.key_skills && { borderColor: "red" }),
                        }}
                        variant="standard"
                        ref={params.InputProps.ref}
                        {...params}
                      />
                      <FormHelperText error={errors.key_skills}>{ errors?.key_skills?.message}</FormHelperText>
                    </FormControl>
                  )}
                  filterOptions={(options, params) => {
                    const filtered = keySkillFilter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      option => inputValue === option.name
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        isActive:true,
                        name: `${inputValue}`,
                      });
                    }

                    return filtered;
                  }}
                  value={getValues("key_skills")}
                  onChange={async (_, data, reason) => {
                    if (reason === "clear") { 
                      props.field.onChange([])
                      return
                    }
                    const lastIndex = data?.length - 1;
                    if (data && data[lastIndex].inputValue) {
                      const { data: skill } = await createKeySkills({
                        name: data[lastIndex].inputValue,
                      });
                      await getKeySKills();
                      props.field.onChange([
                        ...getValues("key_skills"),
                        {
                          name: skill.key_skill_res.name,
                          value: skill.key_skill_res.id,
                        },
                      ]);
                    } else {
                      props.field.onChange(data);
                    }
                  }}
                />
              );
            }}
            name="key_skills"
            control={control}
            rules={{
              required: {
                value: false,
                message:"should not be empty"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            render={params => {
              return (
                <PrimaryAutoComplete
                  // loading={skillLoader}
                  loadingText={"Loading..."}
                  sx={{ width: "100%" }}
                  {...params}
                  // disableCloseOnSelect
                  componentsProps={autocompleteProps}
                  multiple={true}
                  options={companies}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <SecondaryFilledChip
                        deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                        label={option.company_name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  isOptionEqualToValue={(option,value)=>option.id === value.id}
                  renderOption={(props, option, { selected }) => (
                    option.is_active ?
                    <li key={option.id} {...props}>
                      <Checkbox
                        icon={<FeatherIcon icon="square" size="14" />}
                        checkedIcon={
                          <FeatherIcon icon="check-square" size="14" />
                        }
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.company_name}
                    </li> : null
                  )}
                  getOptionLabel={option => option?.company_name}
                  renderInput={params => (
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="functional-outlined"
                        error={errors.assigned_companies}
                      >
                        Companies
                      </PrimaryNormalInputLabel>
                      <MultipleSelectInputs
                        sx={{
                          ...(errors.assigned_companies && {
                            borderColor: "red",
                          }),
                        }}
                        variant="standard"
                        ref={params.InputProps.ref}
                        {...params}
                      />
                      <FormHelperText error={errors.assigned_companies}>{ errors?.assigned_companies?.message}</FormHelperText>
                    </FormControl>
                  )}
                  filterOptions={(options, params) => {
                    const filtered = categoryFilter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      option => inputValue === option.company_name
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        name: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  // defaultValue={getValues("assigned_companies")}
                  value={getValues("assigned_companies")||[]}
                  onChange={async (_, data) => {
                    console.log(data);
                    const lastIndex = data?.length - 1;
                    // if (data && data[lastIndex].inputValue) {
                    //   const { data: skill } = await createCategories({
                    //     name: data[lastIndex].inputValue,
                    //   });
                    //   console.log(skill);
                    //   await getAllFunctionalArea();
                    //   props.field.onChange([
                    //     ...getValues("functional_area"),
                    //     {
                    //       name: skill.key_skill_res.name,
                    //       value: skill.key_skill_res.id,
                    //     },
                    //   ]);
                    // } else {
                    params.field.onChange(data);
                    // setValue("assigned_companies",data)
                    // }
                  }}
                  // inputValue={getValues("assigned_companies")}
                  // onInputChange={(_, value) => {
                  //   params.field.onChange(value)
                  //   setValue("assigned_companies",value)
                  // }}
                />
              );
            }}
            name="assigned_companies"
            control={control}
            rules={{
              required: {
                value: false,
                message:"should not be empty"
              }
            }}
          />
        </Grid>
        <Grid item xs={10} md={10}></Grid>
        <Grid item xs={2} md={2}>
          <PrimaryButton sx={{ width: "100%" }} type="submit">
            submit
          </PrimaryButton>
        </Grid>
      </Grid>
    </form>
  );
}
