import React,{useState,useEffect} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { getAllNotificationsSettings } from "helpers/services/Notifications/NotificationApi";
import {
  NotificationDescTextTypography,
  NotificationDescTypography,
  NotificationTitleTypography,
  MiddleDivider,
  PrimarySwitch,
  SkillTypography,
  CommentTypography,
  PrimaryTypography,
  NotificationTypography
} from "assets/Mui/globalTheme";
import Loading from "store/loading/reducer";
import { NotificationTags,GlobalStatusApp,LocalStatusApp,GlobalStatusEmail,LocalStatusEmail } from "store/Notifications/actions";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";

const NotificationsList = [
  {
    id: 0,
    name: "Account",
    description: {
      heading: "Account settings are updated",
      statusDescription:
        "You will receive a notification when you make a change in the administration such as enabling or disabling features",
    },
    applicationStatus: "true",
    applicationStatus: "true",
  },
  {
    id: 2,
    name: "Users",
    description: {
      heading: "Account settings are updated",
      statusDescription:
        "You will receive a notification when you make a change in the administration such as enabling or disabling features",
    },
    applicationStatus: "true",
    applicationStatus: "true",
  },
  {
    id: 3,
    name: "Organizations",
    description: {
      heading: "Account settings are updated",
      statusDescription:
        "You will receive a notification when you make a change in the administration such as enabling or disabling features",
    },
    applicationStatus: "true",
    applicationStatus: "true",
  },
  {
    id: 4,
    name: "Jobs",
    description: {
      heading: "Account settings are updated",
      statusDescription:
        "You will receive a notification when you make a change in the administration such as enabling or disabling features",
    },
    applicationStatus: "true",
    applicationStatus: "true",
  },
  {
    id: 5,
    name: "Jobs",
    description: {
      heading: "Account settings are updated",
      statusDescription:
        "You will receive a notification when you make a change in the administration such as enabling or disabling features",
    },
    applicationStatus: "true",
    applicationStatus: "true",
  },
  {
    id: 6,
    name: "Jobs",
    description: {
      heading: "Account settings are updated",
      statusDescription:
        "You will receive a notification when you make a change in the administration such as enabling or disabling features",
    },
    applicationStatus: "true",
    applicationStatus: "true",
  },
  {
    id: 7,
    name: "Jobs",
    description: {
      heading: "Account settings are updated",
      statusDescription:
        "You will receive a notification when you make a change in the administration such as enabling or disabling features",
    },
    applicationStatus: "true",
    applicationStatus: "true",
  },
];



function FormRow() {
  return (
    <React.Fragment>
      <Grid item xs={3}>
        {/* <Item>Item</Item> */}
      </Grid>
      <Grid item xs={5}>
       <NotificationTypography varient="h3">Notification Settings</NotificationTypography> 
      </Grid>
      <Grid item xs={2}>
        <NotificationTypography>In Application
        <PrimarySwitch /></NotificationTypography>
      </Grid>
      <Grid item xs={2}>
       <NotificationTypography> Email
        <PrimarySwitch /></NotificationTypography>
      </Grid>
      <MiddleDivider varient="middle" style={{}} />
    </React.Fragment>
  );
}

function NotificationSettings() {

  /* Notifications integration start */
  
  // const dispatch = useDispatch()
  // const [notificationsData, setNotificationsData] = useState()
  // const [loading, setLoading] = useState(true)

  // useEffect(()=>{
  //   fetchNotificationSettings()
  // },[])

  // const fetchNotificationSettings = async()=>{
  //   const {data,status} = await getAllNotificationsSettings()
  //   if(status == 200){
  //     dispatch(NotificationTags(data))
  //     setNotificationsData(data)
  //     setLoading(false)
  //   }
  // }

   /* Notifications integration start */

  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
        <Grid container spacing={1}>
          <Grid container item spacing={1}>
            <FormRow />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          sx={{ marginTop: "20px", overflowY: "scroll", height: "400px" }}
        >
          {NotificationsList?.map(each => {
            return (
              <>
                <Grid lg={3}>
                  <NotificationTitleTypography>
                    {each.name}
                  </NotificationTitleTypography>
                </Grid>
                <Grid lg={5}>
                  <NotificationDescTypography>
                    {each.description.heading}
                  </NotificationDescTypography>
                  <NotificationDescTextTypography>
                    {each.description.statusDescription}
                  </NotificationDescTextTypography>
                </Grid>
                <Grid lg={2}>
                  <PrimarySwitch />
                </Grid>
                <Grid lg={2}>
                  <PrimarySwitch />
                </Grid>
                <MiddleDivider
                  varient="middle"
                  sx={{ borderWidth: "0.1px", borderColor: "pink" }}
                />
              </>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}

export default NotificationSettings;
