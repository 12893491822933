import { Avatar, IconButton, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import {
  GrayDivider,
  GreyTypography,
  StyledSecondaryTypography,
  BlackTypography,
  MediumPrimaryAvatar,
} from "assets/Mui/globalTheme";
import { imageURL } from "common/constants/commonURLS";
import { CA, ZA } from "common/constants/userModules";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ClipBoardData from "helpers/dashboard/clipboard";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import JobCardPopUp from "./jobCardPopUp";
import { useCallback, useState } from "react";

export default function JobPostedCompanyAvatar({ item }) {
  const user = useSelector(state => state.Login);
  const userId = user?.id
  const history = useHistory();
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    setShowPopover(true);
  }
  const handleClose = useCallback(() => {
    setShowPopover(false);
    setAnchorEl(null);
  }, [showPopover]);
  const path = window.location.pathname;
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <div className="image-box">
        <MediumPrimaryAvatar
          alt="Company Logo"
          imgProps={{ loading: "lazy" }}
          src={`${imageURL}${item?.company_details?.company_image}`}
        >
          {item?.company_details?.company_name.charAt(0)?.toUpperCase()}
        </MediumPrimaryAvatar>
        <aside>
          {item?.is_job_post_verified ? (
            <Link to={`../joblist/jobs/${item.id}?user_id=${userId}`}>
              <BlackTypography variant="h5" component="h5">
                {item.job_title}
              </BlackTypography>
            </Link>
          ) : (
            <BlackTypography variant="h5" component="h5">
              {item.job_title}
            </BlackTypography>
          )}
          <Link
            className="h5 mt-2 mb-2"
            // to={`../joblist/jobs/${item.id}`}
            to={`../company/${item?.created_by_user_details?.id}`}
          >
            <GreyTypography variant="h6" component="h6">
              {item?.company_details?.company_name}
            </GreyTypography>
          </Link>
        </aside>
      </div>
      {path !== "/joblist/closed" ? (
        <IconButton style={{ alignSelf: "flex-start" }} onClick={handleClick}>
          <FeatherIcon icon="more-vertical" size="14" />
        </IconButton>
      ) : (
        ""
      )}
      <JobCardPopUp
        handleClose={handleClose}
        id={item?.id}
        anchorEl={anchorEl}
      />
    </Stack>
  );
}
