import { DELETE, POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
import { imageURL } from "common/constants/commonURLS";
import moment from "moment";


function jobPost(data){
    const jobpostDetails = data?.job_post_details?.map((each)=>{
        const formattedTime = moment(each?.updatedAt).format("ddd, DD YYYY");
        // if(!each?.is_job_post_verified){
            return {
                id:each?.id,
                role:each?.job_title,
                title:each?.company_details?.company_name,
                image:`${imageURL}${each?.company_details?.company_image}`,
                // time:each?.updatedAt,
                time:formattedTime,
                key:"to be approve",
                message:`${each?.company_details?.company_name} posted a job`  
            }
        // }
       
    })
    return jobpostDetails
}



export const getAllNotifications = async()=>{

     const {data,status} = await callApi(`company/job_post`)
     const jobPostTweakData = jobPost(data)
    return jobPostTweakData
}

export const getAllNotificationsSettings = async()=>{
    const {data,status} = await callApi(``)
    return data
}