import * as React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { getAllReferalParterns } from "helpers/services/referalPartner";
import { getAllBackgroundUsersList } from "helpers/services/Background/backgroundVerify";
import { imageURL } from "common/constants/commonURLS";
import {
  PrimaryButton,
  PrimaryButtonForTable,
  PrimaryDataGrid,
  PrimarySwitch,
  SmallPrimaryAvatar,
  SmallSizeChip,
} from "assets/Mui/globalTheme";
import { Pagination } from "@mui/material";
import TablePopper from "components/table/header/popper";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import { KeySkillOperator } from "components/table/operators/keySkillOperator";
import { useHistory } from "react-router-dom";
import { PrimaryChip,HoldChip } from "assets/Mui/globalTheme";
import { StyledGridOverlay } from "assets/Mui/globalTheme";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import { CA, CS, ZA } from "common/constants/userModules";
import CompanyTypeModel from "./addCompanyType";
import SkillViewModal from "helpers/Modals/SkillViewModal";

export default function BgvTable() {
  const [referalPartners, setReferalPartners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const loginStore = JSON.parse(localStorage.getItem("authUser"));
  const role = loginStore?.role
  const [viewAddModal, setViewAddModal] = React.useState(false)
  const [openSkillModal, setOpenSkillModal] = React.useState(false);
  const [modalName, setModalName] = React.useState("");
  const [update, setUpdate] = React.useState(false)  
  const [refetch,setRefetch] = React.useState(false)

  const [page, setPage] = React.useState(1)
  const [totalCount,setTotalCount] = React.useState("")
  const rowsPerPage = 20;
  const offset = (page - 1) * rowsPerPage;

  const [selectedRowData, setSelectedRowData] = React.useState(null);
  React.useEffect(() => {
    initialFetch(role,page,rowsPerPage);
  }, [update]);


  React.useEffect(()=>{
    initialFetch(role,page,rowsPerPage);
  },[page])

  const history = useHistory();

  async function initialFetch(role,page,rowsPerPage) {
    setLoading(true);
    const { data, status } = await getAllBackgroundUsersList(role,page,rowsPerPage);
    setTotalCount(Math.ceil((data?.count)/rowsPerPage))
    const result = data?.data?.length > 0 && data?.data?.map((item, index) => ({
      id: item?.id,
      phone_number: item?.phone_number,
      image: `${imageURL}${item?.image}`,
      email: item?.email,
      gender: item?.gender,
      location: `${item?.location?item.location:"N/A"}`,
      name: `${item?.first_name} ${item?.last_name}`,
      Status: `${item.user_approval}`,
      companies_assigned:item?.assigned_company_details?.length > 0
      ? item?.assigned_company_details?.map(item => item?.company_name)?.join(",")
        : "N/A",
      skills: `${
          item?.skill_details?.length > 0
            ? item?.skill_details?.map(item => item?.name)?.join(",")
            : "N/A"
        }`,   
      Categories: `${
          item?.functional_area_details?.length > 0
            ? item?.functional_area_details?.map(item => item?.name)?.join(",")
            : "N/A"
        }`,
      // jobs_applied: item?.get_applied_job_post_counts?.count,
      // resume: item?.resume,
      // experience: "1 d",
      // candidate_referred: "1 d",
      // candidate_onboarded: "1d",
      // total_earnings: "1d",
      // available_earnings: "1d",
      // highest_education: "1d",
      // skills: item?.skills?.map(item => item?.name)?.join(","),
      // bank_account_details: "2d",
    }));
    if (status === 200) {
      setLoading(false);
      // setTotalCount(Math.ceil(data?.count))
    }

    setReferalPartners(result);
  }
  const handleCellClick = params => {
    const field = params.field;
    if (field === "skills") {
      if (params.row.skills !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.skills.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else if (field === "Categories") {
      if (params.row.Categories !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.Categories.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    }
    else if (field === "companies_assigned") {
      if (params.row.companies_assigned !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.companies_assigned.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    }
    else {
      if(!["skills","Categories"].includes(field) && ![CA,CS].includes(loginStore.role))
        history.push(`/common-user/${params.row.id}`);

      // history.push(`/common-user/${params.row.id}`);
    }
  };
  const handleCloseModal = () => {
    setOpenSkillModal(!openSkillModal);
  };
  return (
    <div style={{ padding: "10px", height: "91vh",display:'flex',flexDirection:'column'}}>
      {[ZA].includes(loginStore.role) ?<PrimaryButton
        sx={{width:"max-content !important",ml:"auto !important"}}
        onClick={() => {
          // dispatch({ type: "APPROVE_MODAL" });
          setViewAddModal(true)
        }}
        // startIcon={(<FeatherIcon icon={"add"} size={16} />)}
      >
        Add
      </PrimaryButton>:null}
      <PrimaryDataGrid
        onCellClick={handleCellClick}
        loading={loading}
        // checkboxSelection={true}
        columns={[
          {
            field: "image",
            headerName: "Image",
            filterable: false,
            renderCell: params => {
              return (
                <SmallPrimaryAvatar
                  imgProps={{ loading: "lazy" }}
                  src={params?.formattedValue}
                >
                  {params?.row?.name?.charAt(0)?.toUpperCase()}
                </SmallPrimaryAvatar>
              );
            },
          },
          { field: "name",headerName: "Name", width: 200 },
          { field: "phone_number",headerName: "Phone Number", width: 300 },
          { field: "email",headerName: "Email", width: 300 },
          {
            field: "Status",
            headerName: "Status",
            width: 200,
            renderCell: params => {
              if (params.formattedValue !== "Approve") {
                return <HoldChip label={params?.formattedValue} size="small"/>;
              }
              return <PrimaryChip label={params?.formattedValue} size="small"/>;
            },
          },
          { field: "location", headerName: "Location", width: 400, filterOperators: LocationOperators },
          {
            field: "Categories",
            headerName: "Categories",
            width: 400,
            renderCell: paramss => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {paramss.value && paramss.value !== "N/A" ? (
                  <>
                    {/* <span>
                      {paramss.value.split(",").slice(0, 2).join(",")}
                    </span>
                    {paramss.value.split(",").length > 1 && (
                      <InfoOutlinedIcon
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          opacity: "50%",
                        }}
                      />
                    )} */}
                       {paramss.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
            },
        ["ZA"].includes(role) ? (  
          {
            field: "companies_assigned",
            width: 400,
            headerName:"Companies Assigned",
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center",gap:"8px" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                    {/* <span>{params.value.split(",").slice(0, 2).join(",")}</span>
                    {params.value.split(",").length > 1 && (
                      <InfoOutlinedIcon
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          opacity: "50%",
                        }}
                      />
                    )} */}
                       {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          }
          ) : "",
          
          {
            field: "skills",
            headerName: "Skills",
            width: 400,
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                    {/* <span>{params.value.split(",").slice(0, 2).join(",")}</span>
                    {params.value.split(",").length > 1 && (
                      <InfoOutlinedIcon
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          opacity: "50%",
                        }}
                      />
                    )} */}
                     {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },
          // { field: "employees_count", width: 200 },
          // { field: "candidate_onboarded", width: 200 },
          // { field: "about", width: 200 },
          // { field: "total_bgv_tasks_completed", width: 200 },
          // { field: "available_earnings", width: 200 },
          // { field: "total_earnings", width: 200 },
          // { field: "highest_education", width: 200 },
          // { field: "skills", width: 200, filterOperators: KeySkillOperator },
          // { field: "bank_account_details", width: 200 },
        ]}
        rows={referalPartners}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButtonForTable,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: props => (
            <Pagination
              {...props}
              // color="primary"
              // count={Math.ceil(rowsData.length / rowsPerPage)}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={(_, newPage) => setPage(newPage)}
              showFirstButton
              showLastButton
              sx={{
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#024430",
                    color: "#ffe7bb",
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                  },
                },
              }}
            />
          ),
        }}
      />
      {openSkillModal && (
        <SkillViewModal
          rowdata={selectedRowData}
          closeModalFunc={handleCloseModal}
          openModal={openSkillModal}
          headerName={modalName}
        />
      )}
      {viewAddModal && (
        <CompanyTypeModel isOpen={viewAddModal} updated={()=>setUpdate(pre=>!pre)} toggle={()=>setViewAddModal(false)}  />
      )}
    </div>
  );
}
