import { POST, PUT } from "common/constants/methods";
// !getting company related things
async function companyId() {
  return await JSON.parse(localStorage.getItem("authUser"))?.company_id;
}

//!getting company details
async function companyUserID() {
  return await JSON.parse(localStorage.getItem("authUser"))?.id;
}
const { default: callApi } = require("helpers/callApi");

//!only for company crud

export const handleCompanyDelete = async key => {
  await callApi(`admin_authorise/delete_company/${key}`, {}, "DELETE");
  return data.data;
};
export const getAllCompanies = async () => {
  const data = await callApi("admin_authorise/get_companies_list");
  return data.data;
};
export const getAllCompaniesWithStatus = async () => {
  return await callApi("admin_authorise/get_companies_list");
};

//!may hit errors
export const getSingleCompanies = async key => {
  const data = await callApi(`company_authorise/${key}`);
  return data.data;
};

//!only for getting loggedin company details
export const getLoggedInCompanyDetails = async () => {
  const data = await callApi(
    `company_authorise/${await companyUserID()}`,
    {},
    "GET"
  );
  return data.data;
};
export const viewSingleCompanyDetails = async key => {
  const data = await callApi(`/company_authorise/${key}`);

  return data.data;
};
export const createCompanies = async body => {
  const reqBody = {
    email: body.email,
    first_name: body.first_name,
    last_name: body.last_name,
    phone_number: body.phone_number,
    user_password: body.password,
    is_active: true,
    usertype: body.userType,
    company_name: body.company_name,
  };
  const data = await callApi("/company_authorise/register", reqBody, POST);
  return data;
};
export const updateCompanies = async data => {
  return await callApi(`/company_authorise/${await companyId()}`, data, PUT);
};

//!only for company staff crud

export const createCompanyStaff = async ({
  email,
  first_name,
  last_name,
  user_password,
  usertype,
  mobile
}) => {
  const data = {
    email,
    first_name,
    last_name,
    user_password,
    is_active: true,
    phone_number:mobile,
    usertype,
    company_id: await companyId(),
  };
  return await callApi("company_staff/register", data, POST);
};

export const getAllCompanyStaffList = async () => {
  const result = await callApi(
    `company_authorise/get_company_staff_list?company_id=${await companyId()}`
  );
  return result.data;
};
export const getSingleCompanyStaffDetails = async id => {
  const result = await callApi(
    `company_staff/${id}?company_id=${await companyId()}`
  );
  return result.data;
};

export const deleteCompanyStaff = async id => {
  const data = await callApi(`/company_staff/${id}`, {}, "DELETE");
  return data;
};

export const updateCompanyStaff = async (items, id) => {
  const data = {
    ...items,
    phone_number:items.mobile,
    company_id: await companyId(),
  };  delete data.mobile;

  return await callApi(`company_staff/${id}`, data, PUT);
};
